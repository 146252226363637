import { useContext, useState, useEffect, useRef } from 'react';
import { StepperContext } from '../contexts/StepperContext';
import React from 'react';
import { CgDanger } from 'react-icons/cg'
import social from '../../../img/Flaticon/Component42.webp'
import star from '../../../img/Flaticon/staryello.webp'
import styles from './Content.module.css';


const skillPerStyle = {
    height: '15px',
    background: '#eaff3c',
    borderRadius: '8px',
    width: '0',
    transition: '1s linear',
    position: 'relative',
};

const SkillBar = ({ skillName, percentage }) => {
    // eslint-disable-next-line
    const { userData, setUserData } = useContext(StepperContext);
    const [animatedPercentage, setAnimatedPercentage] = useState(0);
    const skillBarRef = useRef(null);

    useEffect(() => {
        if (skillName === 'HTML') {
            let startTime = null;
            let animatedValue = 0;

            function animate(timestamp) {
                if (!startTime) startTime = timestamp;
                const progress = timestamp - startTime;
                const stepPercentage = progress / 3000; // Dividing by duration in milliseconds (1000ms = 1s)

                if (stepPercentage < 1) {
                    animatedValue = percentage * stepPercentage;
                    setAnimatedPercentage(Math.floor(animatedValue));
                    requestAnimationFrame(animate);
                } else {
                    animatedValue = percentage;
                    setAnimatedPercentage(Math.floor(animatedValue));
                }
            }

            requestAnimationFrame(animate);
        }
    }, [skillName, percentage]);
    useEffect(() => {
        if (animatedPercentage === 100) {
            const delay = 1000;
            setTimeout(() => {
                window.location.href = `https://calendly.com/digital-strategists-murphy/30-minute-strategy-session?name=${userData.username}&email=${userData.userEmail}`
                // window.location.href = `https://calendly.com/digital-strategists-murphy/30-minute-strategy-session/2023-10-17T11:15:00+05:45?month=2023-10&name=${userData.username}&email=${userData.userEmail}`;
            }, delay);
        }

    }, [animatedPercentage, userData.userEmail, userData.username]);

    return (
        <div className={styles.skillWrapStyle} ref={skillBarRef}>
            {/* <div className={styles.skillNameStyle}> */}
            <div className={styles.VideoDiv18} >
                <iframe
                    className={styles.video18}
                    title="loop"
                    src="https://streamable.com/e/7gul55?autoplay=1&nocontrols=1"
                    frameborder="0"
                    allowfullscreen
                    allow="autoplay"
                ></iframe>
            </div>
            {/* </div> */}
            <h4>{animatedPercentage}%</h4>
            <div className={styles.skillBarStyle}>
                <div style={{ ...skillPerStyle, width: `${animatedPercentage}%` }}>
                    {/* {animatedPercentage}% */}
                </div>
            </div>
            <div className={styles.text}>
                <CgDanger />
                <p>Please be patient as we verify your answers</p>
            </div>
            <div classname={styles.google}>
                <img src={social} alt="social" />
            </div>
            <div className={styles.stars}>
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />

            </div>
        </div>
    );
};

const Step18 = () => {
    const skills = [
        { name: 'HTML', percentage: 100 },
    ];
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5, // Change this threshold as needed
    };
    const callback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // Start animation when in view
                entry.target.querySelector('.skill-per').style.width = `${entry.target.getAttribute('data-percentage')}%`;
            }
        });
    };
    useEffect(() => {
        const observer = new IntersectionObserver(callback, observerOptions);
        const skillBars = document.querySelectorAll('.skill-wrrap');

        skillBars.forEach((skillBar) => {
            observer.observe(skillBar);
        });
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line
    }, []);
    return (
        <div className={styles.skillMainStyle}>
            {skills.map((skill, index) => (
                <SkillBar
                    key={index}
                    skillName={skill.name}
                    percentage={skill.percentage}
                />
            ))}
        </div>
    );
};

export default Step18;