import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './web.module.css'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'

const WebService = () => {
    return (
        <div>
            <TopBanner namelink='/services' name="Services" innername="Web Design and Development" />
            <div className={styles.description}>
                <ScrollAnimation>
                    <h4>
                        Want to create a fully customized website for your business?
                    </h4>
                </ScrollAnimation>

            </div>
        </div>
    )
}

export default WebService
