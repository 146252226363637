import React, { useRef, useState } from 'react'
import styles from './Strategy.module.css'
import book from '../../img/books/strategic-planner.png'
import people from '../../img/books/input-icon.png'
import mail from '../../img/books/mail.png'
import call from '../../img/books/call.png'
import thankyou from '../../img/books/thankyou-image.svg'
import gif from '../../img/books/loader.gif'
import star from '../../img/books/star.png'
import loaderJson from '../../img/books/loader.json'
import Lottie from 'react-lottie'

import { motion } from 'framer-motion'
import ScrollAnimation from '../Animation/ScrollAnimation'
import axios from 'axios'
import CombineAnimation from '../Animation/CombineAnimation'

const StrategyPlanner = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const [confirmationPopup, setConfirmationPopup] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const formRef = useRef()
    if (confirmationPopup) {
        document.body.style.overflow = 'hidden'
    }
    if (!confirmationPopup) {
        document.body.style.overflow = ''
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }
    const handleFormSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(formRef.current)

        postFormData(
            formData.get('firstName'),
            formData.get('lastName'),
            formData.get('email'),
            formData.get('phone')
        )
        setConfirmationPopup(true)
        setIsPending(true)
    }

    const postFormData = async (fname, lname, email, phone) => {
        try {
            await axios.post(`${apiUrl}`, {
                firstName: fname,
                lastName: lname,
                email: email,
                phone: phone,
            })
            formRef.current.reset()
            setIsPending(false)
        } catch (err) {
            console.log(err)
            setConfirmationPopup(true)
            alert('Something went wrong. Please try again later.')
        }
    }

    return (
        <div className={styles.outerDiv}>
            <CombineAnimation>
                <div className={styles.main_container}>
                    <div className={styles.strategyContainer}>
                        <figure className={styles.leftBookFigure}>
                            <img
                                src={book}
                                alt=""
                                className={styles.strategyImage}
                            />
                        </figure>
                        <div className={styles.centerContent}>
                            <ScrollAnimation>
                                <h2 className={styles.strategyHeading}>
                                    Planner for Digital Marketing Strategies at
                                    No Cost
                                </h2>
                            </ScrollAnimation>
                            <motion.p
                                initial={{ opacity: 0, y: 75 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true }}
                                className={styles.strategyPara}
                            >
                                To create a digital marketing plan that
                                encourages growth, download our free marketing
                                guide.
                            </motion.p>
                            <motion.div
                                initial={{ opacity: 0, y: 75 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true }}
                                className={styles.bookDetails}
                            >
                                <figure className={styles.figure}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="white"
                                        className="styles.svgIcon"
                                        width={30}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                        />
                                    </svg>

                                    <figcaption className={styles.figContent}>
                                        7 pages
                                    </figcaption>
                                </figure>
                                <figure className={styles.figure}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-6"
                                        width={30}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                        />
                                    </svg>

                                    <figcaption className={styles.figContent}>
                                        15 minutes
                                    </figcaption>
                                </figure>
                            </motion.div>
                        </div>
                        <form
                            className={styles.strategyForm}
                            onSubmit={handleFormSubmit}
                            ref={formRef}
                        >
                            <div>
                                <img src={people} alt="" />
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    required
                                />
                            </div>
                            <div>
                                <img src={people} alt="" />
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    required
                                />
                            </div>
                            <div>
                                <img
                                    src={mail}
                                    alt=""
                                    className={styles.mailIcon}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Your Email"
                                    required
                                />
                            </div>
                            <div>
                                <img
                                    src={call}
                                    alt=""
                                    className={styles.mailIcon}
                                />
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Your Number"
                                    pattern="^\d{10}$"
                                    title="Please enter a valid phone number"
                                    required
                                />
                            </div>
                            <button className={styles.btn}>Download Now</button>
                        </form>
                    </div>
                </div>
            </CombineAnimation>
            {confirmationPopup && (
                <div className={styles.mainPopup}>
                    {isPending ? (
                        <div className={styles.pendingGif}>
                            <Lottie
                                options={defaultOptions}
                                height={400}
                                width={400}
                            />
                        </div>
                    ) : (
                        <div className={styles.popup}>
                            <>
                                <img
                                    src={star}
                                    alt="star"
                                    className={styles.star}
                                />
                                <figure>
                                    <img src={thankyou} alt="" />
                                </figure>
                                <p>
                                    Thank you for your interest in our digital
                                    marketing guide. We have sent this to the
                                    email address you provided.
                                </p>
                                <div className={styles.btnDiv}>
                                    <button
                                        className={styles.closeBtn}
                                        onClick={() =>
                                            setConfirmationPopup(false)
                                        }
                                    >
                                        Close
                                    </button>
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                    width={30}
                                    onClick={() => setConfirmationPopup(false)}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18 18 6M6 6l12 12"
                                    />
                                </svg>
                            </>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default StrategyPlanner
