import { useContext, useEffect, useState } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';
import DragHere from '../../../img/Flaticon/DragHere.webp'
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion"

const Step10 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext)
    const [sliderValue, setSliderValue] = useState(userData.MonthlyRevenue ? userData.MonthlyRevenue : 10000);
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    const [ref, inView] = useInView();
    const [dragHereVisible, setDragHereVisible] = useState(false);
    const [incrementing, setIncrementing] = useState(true);

    useEffect(() => {
        if (sliderValue > 0) {
            setisbuttonDisabled(false); // Enable the button if sliderValue is greater than 0
        } else {
            setisbuttonDisabled(true); // Disable the button if sliderValue is 0 or less
        }
    }, [sliderValue]);

    useEffect(() => {
        if (inView) {
            setDragHereVisible(true);
        }
    }, [inView]);

    useEffect(() => {
        let interval;
        if (dragHereVisible && incrementing) {
            // Increment the slider value every second
            interval = setInterval(() => {
                if (sliderValue > 0) {
                    setSliderValue(sliderValue - 1000);
                } else {
                    setIncrementing(false)
                }
                if (sliderValue < 0) {
                    setSliderValue(0)
                }
            }, 100); // Increment every 1000ms (1 second)
        }
        return () => {
            clearInterval(interval); // Clear the interval when the component unmounts
        };
    }, [dragHereVisible, sliderValue, incrementing]);

    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    const handleChange = (e) => {
        setSliderValue(e.target.value)
        const { name, value } = e.target;
        // Parse the value as a number and set it in the userData object
        setUserData({ ...userData, [name]: parseInt(value) });
        setIncrementing(false);
        setDragHereVisible(false);
    };


    // console.log(userData)
    return (
        <>
            <div ref={ref} className={`${styles.StepOuterDiv} ${styles.Step10OuterDiv} ${styles.slider}`}>
                <h1>What is your current MONTHLY revenue?</h1>
                <p>(We need this info to properly access your situation.)</p>
                <h2>${sliderValue}</h2>
                <input
                    type="range"
                    min="0"
                    max="100000"
                    // style={{ transition: 'all 7s ease' }}
                    value={sliderValue}
                    onChange={(e) => handleChange(e)}
                    name="MonthlyRevenue" // Set the name attribute to "sliderValue"
                />

                {dragHereVisible && (
                    <motion.div
                        className={styles.DragHere}
                        initial={{ x: 70 }} // Initial position (off-screen to the right)
                        animate={{ x: 0 }} // Animate to the original position
                        transition={{ type: 'spring', stiffness: 100, damping: 10 }}
                    >
                        <img src={DragHere} alt="Draghere" />
                    </motion.div>
                )}
            </div>

            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </>
    )
}

export default Step10
