import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const Step17 = ({ handleClick, currentStep, steps }) => {
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true)
    const { userData, setUserData } = useContext(StepperContext)
    const [phoneValue, setPhoneValue] = useState('')
    const [userCountryCode, setUserCountryCode] = useState()
    useEffect(() => {
        if (
            userData.userlastname &&
            userData.usercompanyname &&
            userData.phoneNumber
        ) {
            setisbuttonDisabled(false)
        } else {
            setisbuttonDisabled(true) // Disable the button if both checkboxes are unchecked
        }
    }, [userData.userlastname, userData.usercompanyname, userData.phoneNumber])
    const postData = async () => {
        try {
            const response = await fetch(
                'https://api.murphystechnology.com.au/api/form-data',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData),
                }
            )

            if (response.ok) {
                console.log('Data sent successfully!')
                // Perform any additional actions upon successful data submission
            } else {
                console.error('Failed to send data.')
                // Handle error, if needed
            }
        } catch (error) {
            console.error('Error sending data:', error)
            // Handle error, if needed
        }
    }

    // Trigger the API call when userData changes and the button is clicked
    // useEffect(() => {
    //     if (!isbuttonDisabled) {
    //         postData();
    //     }
    // }, [userData, isbuttonDisabled]);

    useEffect(() => {
        // Use the geolocation API to get the user's country
        fetch('https://ipapi.co/json/')
            .then((response) => response.json())
            .then((data) => {
                const countryCode = data.country
                setUserCountryCode(countryCode)
            })
            .catch((error) => {
                console.error('Error getting user location:', error)
            })
    }, [])
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value })
    }

    const handlePhoneChange = (value) => {
        setPhoneValue(value)
        setUserCountryCode(userCountryCode)
        // if (userCountryCode) {
        //     setPhoneValue(`+${userCountryCode} ${value}`);
        // } else {
        //     // Fallback to the default behavior
        // setPhoneValue(value);
        // }
        setUserData({ ...userData, phoneNumber: value })
    }
    console.log(userData)
    return (
        <>
            <div className={`${styles.StepOuterDiv} ${styles.Step17OuterDiv}`}>
                <h1>
                    BOOM! 👊 It looks like we can DRAMATICALLY help you grow
                    your business using some of our proven funnels and marketing
                    strategies, simply enter your details below!
                </h1>
                <p>
                    Please fill out your details, and you will be taken to a
                    calendar on the next page to pick a time and date that works
                    best for you.
                </p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input
                        onChange={handleChange}
                        value={userData['username'] || ''}
                        name="username"
                        placeholder="First Name*"
                    />
                    <input
                        onChange={handleChange}
                        value={userData['userlastname'] || ''}
                        name="userlastname"
                        placeholder="Last Name*"
                    />
                    <input
                        onChange={handleChange}
                        value={userData['usercompanyname'] || ''}
                        name="usercompanyname"
                        placeholder="Company Name*"
                    />
                    <input
                        onChange={handleChange}
                        value={userData['website'] || ''}
                        name="website"
                        placeholder="Website*"
                    />
                    {/* <PhoneInput
                        placeholder="Enter phone number"
                        value={phoneValue}
                        onChange={handlePhoneChange}
                        styles={{ width: "100%" }}
                    /> */}
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={phoneValue}
                        onChange={handlePhoneChange}
                        defaultCountry={userCountryCode}
                        styles={{ width: '100%' }}
                    />
                </div>
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button
                    className={styles.btn}
                    id={styles.btn1}
                    onClick={() => {
                        handleClick('next')
                        postData()
                    }}
                    disabled={isbuttonDisabled}
                >
                    <div>Book My Session</div>
                </button>
            </div>
        </>
    )
}

export default Step17
