import React from 'react'
import goodbye from '../../../img/Flaticon/goodbye.webp'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs'

const Step1 = ({ handleClick, currentStep, steps }) => {
    return (
        <div className={`${styles.StepOuterDiv} ${styles.Step1OuterDiv}`}>
            <img className={styles.wiggleImage} src={goodbye} alt="goodbye" />
            <h1>Free 30-Minute Scaling Growth Map Session</h1>
            <p>Get your 30-minute scaling growth map call—a $1,000 value—for free and without obligation. This is only for individuals who are driven to expand their businesses. Let's boost your business to the moon if that describes you! 🚀 🌕</p>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Step1
