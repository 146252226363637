import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'


const MobileApp = () => {
    return (
        <div>
            <TopBanner namelink='/services' name="Services" innername="Mobile Application Development" />
        </div>
    )
}

export default MobileApp
