import React from 'react'
import styles from './HeroSection.module.css'
import TextSpan from '../TextSpan'
import TextAnimation from '../Animation/TextAnimation'
// import image1 from '../../img/Home/homebanner5 1.webp'
import image1 from '../../img/Home/webimage41.webp'
import image2 from '../../img/Home/webimage61.webp'

// import image2 from '../../img/Home/homebanner2 1.webp'
import badge from '../../img/Logos/spinner.webp'
import Laravel from '../../img/Logos/Laravel.webp'
import React1 from '../../img/Logos/React.webp'
import Figma from '../../img/Logos/Figma.webp'
import { HiMiniArrowLongRight } from 'react-icons/hi2'
import ScrollAnimation from '../Animation/ScrollAnimation'
import Client1 from '../../img/Brands/1.webp'
import Client2 from '../../img/Brands/2.webp'
import Client3 from '../../img/Brands/3.webp'
import Client4 from '../../img/Brands/4.webp'
import Client5 from '../../img/Brands/5.webp'
import Client6 from '../../img/Brands/6.webp'
import Client7 from '../../img/Brands/7.webp'
import Client8 from '../../img/Brands/8.webp'
import Client9 from '../../img/Brands/10.webp'
import Client10 from '../../img/Brands/11.webp'
import ScrollTopDiv from '../Animation/ScrollTopDiv'
import ImageAnimation from '../Animation/ImageAnimation'
import Popup from './popup'
import './searchButton.css'

const HeroSection = () => {
    //  For 10X
    const sentence = 'Website Design'.split('')
    const sentencefirst = ' For 10X '.split('')
    const [searchValue, setSearchValue] = React.useState('')

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        let submitValue
        submitValue = searchValue.split('www.').pop()
        if (submitValue === '') return alert('Please enter a valid domain name')
        window.location.href = `https://client.murphystechnology.com.au/domain-names/search/?domain=${submitValue}`
    }

    return (
        <div className={styles.body}>
            <div className={styles.HeroSection}>
                <div className={styles.container}>
                    <div className={styles.LeftSection}>
                        <p>GROW 10X FASTER , BETTER, SMARTER</p>
                        {sentence.map((letter, index) => {
                            return (
                                <TextSpan key={index}>
                                    {letter === ' ' ? '\u00A0' : letter}
                                </TextSpan>
                            )
                        })}
                        <br />
                        {sentencefirst.map((letter, index) => {
                            return (
                                <TextSpan key={index}>
                                    {letter === ' ' ? '\u00A0' : letter}
                                </TextSpan>
                            )
                        })}
                        <div>
                            <TextAnimation />
                            {/* <Popup /> */}
                        </div>
                        <p className={styles.para}>
                            Growing a business is hard. We make it a whole lot
                            easier, more predictable, less stressful, and more
                            fun.
                        </p>
                        <form
                            onSubmit={handleSearchSubmit}
                            className="search-domain"
                        >
                            <h3>Find your Perfect Domain</h3>
                            <div className="general-input">
                                <input
                                    type="text"
                                    name="domain"
                                    value={searchValue}
                                    onChange={(e) => {
                                        let userInput = e.target.value
                                        if (searchValue === '') {
                                            userInput = 'www.' + e.target.value
                                        } else {
                                            userInput = e.target.value
                                        }
                                        // Check if the user input starts with "www."
                                        if (
                                            userInput === 'www.' ||
                                            userInput.startsWith('www.')
                                        ) {
                                            // Update the value in state
                                            setSearchValue(userInput)
                                        } else if (
                                            userInput.startsWith('www')
                                        ) {
                                            // If user removed the dot, fix it again
                                            setSearchValue(userInput + '.')
                                        } else {
                                            // If user tries to delete the fixed "www." portion, prevent it
                                            setSearchValue('www.')
                                        }
                                    }}
                                    placeholder="Type the domain you want"
                                    className="fill-input"
                                />
                                <input
                                    type="submit"
                                    value="Search"
                                    className="btn btn-default-yellow-fill"
                                />
                            </div>
                        </form>
                        <div class="domain-prices">
                            <ul>
                                <li class="tld" style={{ color: '#a2ff00' }}>
                                    com.au <div class="price">$ 22.42/yr</div>
                                </li>
                                {/* <li class="tld" style={{ color: '#a2ff00' }}>
                                    net.au <div class="price">$ 31.39</div>
                                </li> */}
                                <li class="tld" style={{ color: '#a2ff00' }}>
                                    .com <div class="price">$ 35.40/yr</div>
                                </li>
                                <li class="tld" style={{ color: '#a2ff00' }}>
                                    Email <div class="price">$ 3.59/mo</div>
                                </li>
                            </ul>
                        </div>
                        {/* <div className={styles.buttonsDiv}> */}
                        <button className={styles.btn} id={styles.btn1}>
                            <a href="https://www.seo.murphystechnology.com.au/">
                                SEO Package
                            </a>
                        </button>
                        <button className={styles.btnSimple} id={styles.btn1}>
                            <div>
                                <a href="https://www.murphystechnology.com.au/StrategySession">
                                    Free Consultation
                                    <div>
                                        {/* <IconContext.Provider value={{ size: '25px' }}> */}
                                        <HiMiniArrowLongRight />
                                        {/* </IconContext.Provider> */}
                                    </div>
                                </a>
                            </div>
                        </button>
                        {/* </div> */}
                        <img
                            className={styles.Laravel1}
                            src={Laravel}
                            alt="image2"
                        />
                        <img
                            className={styles.React2}
                            src={React1}
                            alt="image2"
                        />
                        <div className={styles.floatingImage1}>
                            <img src={badge} alt="badge" />
                        </div>
                        <img
                            className={styles.Figma1}
                            src={Figma}
                            alt="image2"
                        />
                    </div>
                    <div className={styles.RightSection}>
                        <img
                            className={styles.Laravel}
                            src={Laravel}
                            alt="image2"
                        />
                        <img
                            className={styles.React1}
                            src={React1}
                            alt="image2"
                        />

                        <ImageAnimation>
                            <div className={styles.image1}>
                                <img
                                    className={styles.image1}
                                    src={image2}
                                    alt="image2"
                                />
                            </div>
                        </ImageAnimation>
                        <div className={styles.floatingImage}>
                            <img src={badge} alt="badge" />
                        </div>
                        <img
                            className={styles.Figma}
                            src={Figma}
                            alt="image2"
                        />

                        <ImageAnimation>
                            <div className={styles.image2}>
                                <img src={image1} alt="image2" />
                            </div>
                        </ImageAnimation>
                    </div>
                </div>
            </div>
            <ScrollTopDiv>
                <div className={styles.Brands}>
                    <div className={styles.Brandstitle}>
                        <ScrollAnimation>
                            <p>Collaborated</p>
                        </ScrollAnimation>
                        <ScrollAnimation>
                            <h1>Brand Experience</h1>
                        </ScrollAnimation>
                    </div>
                    <div className={styles.gridContainer}>
                        <div className={styles.gridItem}>
                            <img src={Client1} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client7} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client4} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client6} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client5} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client2} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client8} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client9} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client10} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client3} alt="client1" />
                        </div>
                    </div>
                </div>
            </ScrollTopDiv>
        </div>
    )
}

export default HeroSection
