import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import ScrollToTop from './pages/ScrollToTop/ScrollToTop'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Process from './pages/Process/Process'
import Contact from './pages/Contact/Contact'
import Career from './pages/Career/Career'
import FAQ from './pages/FAQ/FAQ'
import Services from './pages/Services/Services'
import WebService from './pages/Services/WebService'
import DigitalMarketing from './pages/Services/DigitalMarketing'
import Graphics from './pages/Services/Graphics'
import MobileApp from './pages/Services/MobileApp'
import ActualCareer from './pages/ActualCareer/ActualCareer'
import StrategySession from './components/Survey/StrategySession'
import Reviews from './pages/Reviews/Reviews'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import './App.css'

function App() {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
    }, [])
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Navbar />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/process" exact element={<Process />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/reviews" exact element={<Reviews />} />
                <Route path="/services" element={<Services />} />
                <Route path="/webservice" element={<WebService />} />
                <Route
                    path="/digitalMarketing"
                    element={<DigitalMarketing />}
                />
                <Route path="/graphics" element={<Graphics />} />
                <Route path="/mobileapp" element={<MobileApp />} />
                <Route path="/career" element={<ActualCareer />} />
                <Route path="/affiliates" exact element={<Career />} />
                <Route path="/FAQ" exact element={<FAQ />} />
            </Routes>
            <Footer />

            <Routes>
                <Route
                    path="/StrategySession"
                    exact
                    element={<StrategySession />}
                />
                <Route
                    path="/StrategySession/:id"
                    exact
                    element={<StrategySession />}
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App
