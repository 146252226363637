import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

const Step12 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext)
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    useEffect(() => {
        // Check if either "Product" or "Service" is checked
        if (userData.AboutObstacklesInBusiness === undefined || userData.AboutObstacklesInBusiness === "") {
            setisbuttonDisabled(true); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(false); // Disable the button if both checkboxes are unchecked
        }
    }, [userData.AboutObstacklesInBusiness]);
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value })
    }
    return (
        <>
            <div className={`${styles.StepOuterDiv} ${styles.Step11OuterDiv}`}>
                <h1>{userData.username} ,be honest.. What's the #1 biggest obstacle holding you back from hitting your revenue goal?</h1>
                <p>(Don’t skip the hairy details. We need to know about every score and every sore…. so give as much detail as you can about WHAT it is you feel is holding you back)</p>
                <textarea rows="7" onChange={handleChange} value={userData["AboutObstacklesInBusiness"] || ""} name="AboutObstacklesInBusiness" placeholder='' />
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </>
    )
}

export default Step12
