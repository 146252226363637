

import { useContext, useState, useEffect } from 'react';
import { StepperContext } from '../contexts/StepperContext';
import React from 'react';
import Product from '../../../img/Flaticon/product.webp';
import Tools from '../../../img/Flaticon/support.webp';
import styles from './Content.module.css';
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

const Step4 = ({ handleClick, currentStep, steps }) => {
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    const { userData, setUserData } = useContext(StepperContext);
    const [valueProduct, setvalueProduct] = useState(userData.Product ? true : false);
    const [valueService, setvalueService] = useState(userData.Service ? true : false);

    useEffect(() => {
        // Check if either "Product" or "Service" is checked
        if (valueProduct || valueService) {
            setisbuttonDisabled(false); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(true); // Disable the button if both checkboxes are unchecked
        }
    }, [valueProduct, valueService]);

    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }

    const handleChange = (name) => {
        // Determine the new values for both "Service" and "Product" checkboxes
        let newProductValue = valueProduct;
        let newServiceValue = valueService;

        if (name === "Product") {
            newProductValue = true;
            newServiceValue = false;
        } else if (name === "Service") {
            newProductValue = false;
            newServiceValue = true;
        }

        // Update the state and userData object with the new values
        setvalueProduct(newProductValue);
        setvalueService(newServiceValue);

        setUserData((prevUserData) => ({
            ...prevUserData,
            Product: newProductValue,
            Service: newServiceValue,
        }));
    };



    return (
        <div className={`${styles.StepOuterDiv} ${styles.Step4OuterDiv}`}>
            <p>Okay thanks for that {userData.username}, to find out if Murphy's is a good fit for helping you grow your business... We've got a few questions.</p>
            <h1>First, What do you sell?</h1>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <input
                        type='checkbox'
                        id="Product"
                        name="Product"
                        checked={userData.Product === true}
                        onChange={() => handleChange("Product")}
                    />
                    <label htmlFor="Product">
                        <img src={Product} alt="Product" />
                        <p>Product</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input
                        type='checkbox'
                        id="Service"
                        name="Service"
                        checked={userData.Service === true}
                        onChange={() => handleChange("Service")}
                    />
                    <label htmlFor="Service">
                        <img src={Tools} alt="Service" />
                        <p>Service</p>
                    </label>
                </div>
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Step4;