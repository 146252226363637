import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

function Step14({ handleClick, currentStep, steps }) {
    const { userData, setUserData } = useContext(StepperContext)
    const [selectedOption, setSelectedOption] = useState(null);
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    useEffect(() => {
        if (userData.SatisfactionScale === undefined || userData.SatisfactionScale === "") {
            setisbuttonDisabled(true); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(false); // Disable the button if both checkboxes are unchecked
        }
    }, [userData.SatisfactionScale]);
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value })
    };
    console.log(userData)
    return (
        <>
            <div className={`${styles.StepOuterDiv} ${styles.Step14OuterDiv}`}>
                <p>We're almost done, just 2 more questions.</p>
                <h1>On a scale of 1-10... (1 being I'm fine where I am, and 10 being I'll do anything to reach my revenue goals.) What number are you?</h1>
                <div className={styles.optionsDiv14}>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="1"
                                checked={selectedOption === "1"}
                                onChange={handleOptionChange}
                            /> 1
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="2"
                                checked={selectedOption === "2"}
                                onChange={handleOptionChange}
                            /> 2
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="3"
                                checked={selectedOption === "3"}
                                onChange={handleOptionChange}
                            /> 3
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="4"
                                checked={selectedOption === "4"}
                                onChange={handleOptionChange}
                            /> 4
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="5"
                                checked={selectedOption === "5"}
                                onChange={handleOptionChange}
                            />5
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="6"
                                checked={selectedOption === "6"}
                                onChange={handleOptionChange}
                            />  6
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="7"
                                checked={selectedOption === "7"}
                                onChange={handleOptionChange}
                            />  7
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="8"
                                checked={selectedOption === "8"}
                                onChange={handleOptionChange}
                            />  8
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="9"
                                checked={selectedOption === "9"}
                                onChange={handleOptionChange}
                            />  9
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="SatisfactionScale"
                                value="10"
                                checked={selectedOption === "10"}
                                onChange={handleOptionChange}
                            />  10
                        </label>
                    </div>

                </div>
                {/* <input onChange={handleChange} value={userData["userEmail"] || ""} name="userEmail" placeholder='Email*' /> */}
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </>
    )
}

export default Step14
