import React, { useState } from 'react'
import styles from './Testimonials.module.css'

import right from '../../img/Flaticon/right.webp'
import left from '../../img/Flaticon/left.webp'
import { createPortal } from 'react-dom'
import { BsPlayCircle } from 'react-icons/bs'
import Modal from '../Modal/Modal'
import ScrollAnimation from '../Animation/ScrollAnimation'
const Testimonial = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedClient, setselectedClient] = useState('sam')
    // eslint-disable-next-line 
    const containerSlides = {
        width: "200px",
        height: "200px",
        margin: "0"
    }
    const handleButtonClick = (value) => {
        setModalOpen(false)
    }
    const handleVideoClick = (value) => {
        setselectedClient(value)
        setModalOpen(true)
    }
    return (
        <div className={styles.testimonials}>
            {/* modal */}
            {modalOpen &&
                createPortal(
                    <Modal
                        onClose={handleButtonClick}
                        onSubmit={handleButtonClick}
                        onCancel={handleButtonClick}
                    >
                        <div className={styles.modalDiv} >
                            {selectedClient === 'sam' &&
                                <>
                                    <iframe id="videoControl1" className={styles.video} title='client1' src="https://www.youtube.com/embed/zhsFxxxcwGI?rel=0" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                    <div className={styles.modelFooter}>
                                        <h2>Feedback From Sam Xerri</h2>
                                    </div>
                                </>
                            }
                            {selectedClient === 'billy' &&
                                <>
                                    <iframe id="videoControl1" className={styles.video} title='client2' src="https://www.youtube.com/embed/mKqzDdMwtj0?rel=0" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                    <h2>Feedback From Billy Livingstone</h2>
                                </>
                            }
                            {selectedClient === 'yasir' &&
                                <>
                                    <iframe id="videoControl1" className={styles.video} title='client3' src="https://www.youtube.com/embed/aQU9Zd9TOvo?rel=0" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                    <h2>Feedback From Yasir Abbas</h2>
                                </>
                            }
                        </div>
                    </Modal>,
                    document.body
                )}
            {/* modal */}
            <ScrollAnimation>
                <h4> MORE THAN 1000+ COMPANIES TRUSTED US WORLDWIDE</h4>
            </ScrollAnimation>
            <ScrollAnimation>
                <h1>Creating </h1>
            </ScrollAnimation>
            <ScrollAnimation>
                <h1>Brands That Matter</h1>
            </ScrollAnimation>
            <div className={styles.testimonialsDiv}>
                <div className={styles.Client}>
                    <div className={styles.InnerClient1}>
                        <div className={styles.button} onClick={() => handleVideoClick('billy')}>
                            <BsPlayCircle />
                        </div>
                        <div className={styles.text}>
                            <h3>BILLY LIVINGSTONE</h3>
                            <h5>MKR Painting And Maintenance</h5>
                            <p><img src={right} alt="quote" />Murphys Technology I could not speak highly engouh for them, They were fantastic, They were great. Thanks a lot... <img src={left} alt="quote" /></p>
                        </div>
                    </div>
                    <div className={styles.InnerClient2}>
                        <div className={styles.button} onClick={() => handleVideoClick('yasir')}>
                            <BsPlayCircle />
                        </div>
                        <div className={styles.text}>
                            <h3>YASIR ABBAS</h3>
                            <h5>Abbas Painting And Decorating</h5>
                            <p><img src={right} alt="quote" />Hi! this is yasir from Abbas Painting and Decorating, I really recommend Murphys Technology for doing such a great and wonderful job... <img src={left} alt="quote" /></p>
                        </div>
                    </div>
                    <div className={styles.InnerClient3}>
                        <div className={styles.button} onClick={() => handleVideoClick('sam')}>
                            <BsPlayCircle />
                        </div>
                        <div className={styles.text}>
                            <h3>SAM XERRI</h3>                                <h5>Excom Civil</h5>
                            <p><img src={right} alt="quote" />We started using Murphys Technology early december 2018, since then we've found them to be so professional. They created the fantastic website...<img src={left} alt="quote" /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
