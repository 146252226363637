import React from 'react'
import styles from './Marquee.module.css'
import Marquee from "react-fast-marquee";
import Marqueeimg from '../../img/Home/MarqueeImg2.webp'
import { GiFlowerStar } from 'react-icons/gi'
import RightArrow from '../../img/Flaticon/rightArrow.webp'
import development from '../../img/Flaticon/income.webp'
import branding from '../../img/Flaticon/package.webp'
import marketing from '../../img/Flaticon/bullhorn.webp'
import design from '../../img/Flaticon/design.webp'
import ScrollAnimation from '../Animation/ScrollAnimation';
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router';
const MarqueeSection = () => {
    const navigate = useNavigate();
    const hrVariants = {
        initial: {
            background: "transparent",
            width: "0%",
        },
        hover: {
            background: "linear-gradient(to right, #007bff 0%, #c600ff 100%)", // Change this to your desired color
            width: "90%",
            transition: {
                background: { duration: 1 },
                width: { duration: 0.7 },
            },
        },
    };
    return (
        <div className={styles.outerDiv}>
            <div className={styles.DesignDiv}>
                <div className={styles.heading}>
                    <ScrollAnimation>
                        <h4>
                            WEB DESIGN & DEVELOPMENT
                        </h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>THE POWER IS IN  </h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>THE PLATFORM</h1>
                    </ScrollAnimation>
                </div>
                <div className={styles.rows} onClick={() => navigate('/services')}>
                    <div className={styles.icons}>
                        <img src={development} alt="rightarrow" />
                    </div>
                    <div className={styles.text}>
                        {/* <img src={income} alt="rightarrow" /> */}
                        <h2>Development</h2>
                        <h5>
                            WEB DESIGN AND DEVELOPMENT</h5>
                    </div>
                    <div className={styles.Buttons}>
                        <button className={styles.btn} id={styles.btn1}>Creative</button>
                        <button className={styles.btn} id={styles.btn1}>Accessibility</button>
                    </div>
                    <div className={styles.arrow}>
                        <img src={RightArrow} alt="rightarrow" />
                    </div>

                </div>
                <motion.hr
                    initial="initial"
                    whileInView="hover"
                    variants={hrVariants}
                />
                <div className={styles.rows} onClick={() => navigate('/services')}>

                    <div className={styles.icons}>
                        <img src={branding} alt="rightarrow" />
                    </div>
                    <div className={styles.text}>
                        {/* <img src={income} alt="rightarrow" /> */}
                        <h2>Marketing</h2>
                        <h5>DIGITAL MARKETING AND SOCIAL MEDIA MARKETING</h5>
                    </div>
                    <div className={styles.Buttons}>
                        <button className={styles.btn} id={styles.btn1}>Creative</button>
                        <button className={styles.btn} id={styles.btn1}>Accessibility</button>
                    </div>
                    <div className={styles.arrow}>
                        <img src={RightArrow} alt="rightarrow" />
                    </div>

                </div>
                <motion.hr
                    initial="initial"
                    whileInView="hover"
                    variants={hrVariants}
                />
                <div className={styles.rows} onClick={() => navigate('/services')}>
                    <div className={styles.icons}>
                        <img src={marketing} alt="rightarrow" />
                    </div>
                    <div className={styles.text}>
                        {/* <img src={income} alt="rightarrow" /> */}
                        <h2>Branding & SEO</h2>
                        <h5>GRAPHIC DESIGN AND BRANDING</h5>
                    </div>
                    <div className={styles.Buttons}>
                        <button className={styles.btn} id={styles.btn1}>Creative</button>
                        <button className={styles.btn} id={styles.btn1}>Accessibility</button>
                    </div>
                    <div className={styles.arrow}>
                        <img src={RightArrow} alt="rightarrow" />
                    </div>

                </div>
                <motion.hr
                    initial="initial"
                    whileInView="hover"
                    variants={hrVariants}
                />
                <div className={styles.rows} onClick={() => navigate('/services')}>
                    <div className={styles.icons}>
                        <img src={design} alt="rightarrow" />
                    </div>
                    <div className={styles.text}>
                        {/* <img src={income} alt="rightarrow" /> */}
                        <h2>
                            MOBILE APP</h2>
                        <h5>
                            MOBILE APPLICATION DEVELOPMENT AND DESIGN</h5>
                    </div>
                    <div className={styles.Buttons}>
                        <button className={styles.btn} id={styles.btn1}>Creative</button>
                        <button className={styles.btn} id={styles.btn1}>Accessibility</button>
                    </div>
                    <div className={styles.arrow}>
                        <img src={RightArrow} alt="rightarrow" />
                    </div>

                </div>
                <motion.hr
                    initial="initial"
                    whileInView="hover"
                    variants={hrVariants}
                />
            </div>
            <div className={styles.MarqueeSection}>
                <h4>
                    MORE THAN 1000+ COMPANIES TRUSTED US WORLDWIDE
                </h4>
                <h1 className={styles.First}>
                    <span>INNOVATED</span>
                    <Marquee className={styles.FirstMarquee}>
                        <GiFlowerStar className={styles.Star} /> Laravel <GiFlowerStar className={styles.Star} /> HTML <GiFlowerStar className={styles.Star} />CSS <GiFlowerStar className={styles.Star} />ReactJS
                    </Marquee>
                    <span> ON-SIDE</span>
                </h1>
                <h1 className={styles.Second}>SEARCH ENGINE
                    <span className={styles.FourthTexts}>EXPERTS</span>
                    <img src={Marqueeimg} alt="Marqueee" />
                </h1>
                <h1 className={styles.Third}>
                    <Marquee className={styles.FirstMarquee}>
                        <GiFlowerStar className={styles.Star} /> SEO <GiFlowerStar className={styles.Star} /> Branding <GiFlowerStar className={styles.Star} />Marketing
                    </Marquee>
                    <span>SOCIAL MARKETING</span>
                </h1>
                <h1 className={styles.Fourth}>
                    <span className={styles.FourthText}>SINCE 2009</span>
                    <Marquee className={styles.LastMarquee}>
                        <GiFlowerStar className={styles.Star} /> Graphics <GiFlowerStar className={styles.Star} /> Designing <GiFlowerStar className={styles.Star} /> Mobile <GiFlowerStar className={styles.Star} /> App <GiFlowerStar className={styles.Star} /> WEB <GiFlowerStar className={styles.Star} /> Development
                    </Marquee>
                </h1>
            </div>
        </div >
    )
}

export default MarqueeSection
