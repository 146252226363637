import React, { useState } from 'react'
import styles from './Pricing.module.css'
import ScrollAnimation from '../Animation/ScrollAnimation'
import CombineAnimation from '../../components/Animation/CombineAnimation'
// import star from '../../img/Flaticon/setting.png'

const Pricing = () => {
    const [selectedPlan, setselectedPlan] = useState('Value')
    return (
        <div className={styles.Pricing}>
            <div className={styles.Heading}>
                {/* <img src={star} alt="start" /> */}
                <ScrollAnimation>
                    <h4>PLANS AND PRICING</h4>
                </ScrollAnimation>
            </div>
            <ScrollAnimation>
                <h1>AFFORDABLE PRICING. </h1>
            </ScrollAnimation>
            <ScrollAnimation>
                <h1>EASY SCALING.</h1>
            </ScrollAnimation>
            <div className={styles.SelectionDiv}>
                <button
                    onClick={() => setselectedPlan('Value')}
                    className={
                        selectedPlan === 'Value'
                            ? styles.activePlan
                            : styles.notActive
                    }
                >
                    Small Business
                </button>
                <button
                    onClick={() => setselectedPlan('Power')}
                    className={
                        selectedPlan === 'Power'
                            ? styles.activePlan
                            : styles.notActive
                    }
                >
                    Startup
                </button>
                <button
                    onClick={() => setselectedPlan('Ecommerce')}
                    className={
                        selectedPlan === 'Ecommerce'
                            ? styles.activePlan
                            : styles.notActive
                    }
                >
                    Ecommerce
                </button>
            </div>
            {selectedPlan === 'Value' && (
                <div className={styles.PricingDetails}>
                    <CombineAnimation>
                        <div
                            className={`${styles.LeftSection} ${styles.LeftSection1}`}
                        >
                            <div className={styles.PriceCircle}>
                                <p>$2500</p>
                                <p> One-Time</p>
                            </div>
                            <div className={styles.PriceText}>
                                <h2>Website & Branding</h2>
                                <p>Value plan for your small business</p>
                            </div>
                        </div>

                        <div className={styles.RightSection}>
                            <ul>
                                <li>10 pages</li>
                                <li>Advanced Website Forms</li>
                                <li>Advanced Software Integration</li>
                                <li>Advanced SEO for 6 months</li>
                                <li>Domain Management</li>
                                <li>Social media Integration</li>
                                <li>Custom Made Sophisticated Design</li>
                                <li>Advanced Website Security with SSL</li>
                                <li>3 Revisions</li>
                                <li>1000MB web space</li>
                                <li>24X7 Email support</li>
                                <li>Logo design with 3 revisions</li>
                            </ul>

                            <button className={styles.btn} id={styles.btn1}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://murphystechnology.com.au/contact"
                                >
                                    Get Started
                                </a>
                            </button>
                        </div>
                    </CombineAnimation>
                </div>
            )}
            {selectedPlan === 'Power' && (
                <div className={styles.PricingDetails}>
                    <CombineAnimation>
                        <div
                            className={`${styles.LeftSection} ${styles.LeftSection2}`}
                        >
                            <div className={styles.PriceCircle}>
                                <p>$1299</p>
                                <p> One-Time</p>
                            </div>
                            <div className={styles.PriceText}>
                                <h2>Website & Branding</h2>
                                <p>For startups or those on a budget</p>
                            </div>
                        </div>
                        <div className={styles.RightSection}>
                            <ul>
                                <li>8 pages</li>
                                <li>Basic Website Forms</li>
                                <li>Basic Software Integration</li>
                                <li>Advance SEO for 4 months</li>
                                <li>Domain Management</li>
                                <li>Social media Integration</li>
                                <li>Custom Made Sophisticated Design</li>
                                <li>Advanced Website Security with SSL</li>
                                <li>2 Revisions</li>
                                <li>500MB web space</li>
                                <li>24X7 Email support</li>
                                <li>Logo design with 2 revisions</li>
                            </ul>

                            {/* <button className={styles.btn} id={styles.btn1}>Buy Now</button> */}
                            {/* <form action="https://www.paypal.com/cgi-bin/webscr" className={styles.PriceButton} method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="DA27HX2YBCC64" />

                                <input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_buynow_SM.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" style={{ width: "100px" }} />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_AU/i/scr/pixel.gif" width="1" height="1" />
                            </form> */}
                            <button className={styles.btn} id={styles.btn1}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://murphystechnology.com.au/contact"
                                >
                                    Get Started
                                </a>
                            </button>
                        </div>
                    </CombineAnimation>
                </div>
            )}
            {selectedPlan === 'Ecommerce' && (
                <div className={styles.PricingDetails}>
                    <CombineAnimation>
                        <div
                            className={`${styles.LeftSection} ${styles.LeftSection3}`}
                        >
                            <div className={styles.PriceCircle}>
                                <p>$4000</p>
                                <p> One-Time</p>
                            </div>
                            <div className={styles.PriceText}>
                                <h2>Website & Branding</h2>
                                <p>Showcase and sell your products online</p>
                            </div>
                        </div>
                        <div className={styles.RightSection}>
                            <ul>
                                <li> Unlimited Pages</li>
                                <li>Ultimate Website Forms</li>
                                <li>Ultimate Software Integration</li>
                                <li>Online Payment Portal</li>
                                <li>Domain Management</li>
                                <li>Social media Integration</li>
                                <li>Custom Made Sophisticated Design</li>
                                <li>Advanced Website Security with SSL</li>
                                <li>6 Revisions</li>
                                <li>10TB web space</li>
                                <li>24X7 Email and Call Support</li>
                                <li>Logo design with 5 revisions</li>
                            </ul>

                            {/* <button className={styles.btn} id={styles.btn1}>Buy Now</button> */}
                            {/* <form action="https://www.paypal.com/cgi-bin/webscr" className={styles.PriceButton} method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="8H7DDYF59NBZL" />

                                <input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_buynow_SM.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" style={{ width: "100px" }} />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_AU/i/scr/pixel.gif" width="1" height="1" />
                            </form> */}

                            {/* <div style={{
                                overflow: "auto", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center", width: "259px", background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.1)", boxShadow: " -2px 10px 5px rgba(0, 0, 0, 0)",
                                borderRadius: "10px",
                                fontFamily: " Domine, SQ Market, Helvetica, Arial, sans-serif"
                            }}>
                                <div style={{ padding: "20px" }}>
                                    <a target="_blank" href="https://square.link/u/iuslMBp7?src=embed" style={{
                                        display: "inline-block",
                                        fontSize: "18px",
                                        lineHeight: "48px",
                                        height: "48px",
                                        color: "#ffffff",
                                        minWidth: "212px",
                                        backgroundColor: "#2693ff",
                                        textAlign: "center",
                                        boxShadow: " 0 0 0 1px rgba(0,0,0,.1) inset",
                                        borderRadius: "50px"
                                    }}>Buy now</a>
                                </div>

                            </div> */}
                            <a
                                target="_blank"
                                href="https://murphystechnology.com.au/contact"
                                rel="noreferrer"
                            >
                                <button className={styles.btn} id={styles.btn1}>
                                    Get Started
                                </button>
                            </a>
                        </div>
                    </CombineAnimation>
                </div>
            )}
        </div>
    )
}

export default Pricing
