import React, { useState, useEffect, useRef } from 'react'
import styles from './Stepper.module.css'
import { TiTick } from 'react-icons/ti'

const Stepper = ({ steps, currentStep }) => {
    const [newStep, setNewStep] = useState([]);
    const stepRef = useRef();
    const updateStep = (stepNumber, steps) => {
        const newSteps = [...steps]
        let count = 0;
        while (count < newSteps.length) {
            //current step
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true
                }
                count++;
            }
            else if (count < stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true
                }
                count++;
            }
            else {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false
                }
                count++;
            }
        }
        return newSteps;
    }
    useEffect(() => {
        // create object
        const stepsState = steps.map((step, index) =>
            Object.assign({}, {
                description: step,
                complete: false,
                highlighted: index === 0 ? true : false,
                selected: index === 0 ? true : false,
            })
        );
        stepRef.current = stepsState;
        const current = updateStep(currentStep - 1, stepRef.current);
        setNewStep(current)
    }, [steps, currentStep])

    const displaySteps = newStep.slice(0, -1).map((step, index) => {
        return (
            <>
                {/* Hello */}
                <div key={index} className={`${index !== newStep.length - 1 ? styles.outerSection : ''}`}>
                    <div className={step.selected ? `${styles.selected} ${styles.OuterinnerSection}` : styles.OuterinnerSection}>
                        <div className={index === 0 ? styles.nodisplay : styles.customline}>{/* Display line */}</div>
                        <div className={styles.Rounded}>
                            {/* Display number */}

                            {step.completed ? (
                                <TiTick />
                            ) : ""}
                        </div>

                    </div>
                </div>
            </>
        )
    })
    return (
        <div className={styles.TopLine}>
            {displaySteps}
        </div>
    )
}

export default Stepper
