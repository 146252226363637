import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

const Step13 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext);
    const [selectedOption, setSelectedOption] = useState(userData.WhenToSolveTheProblem);
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    useEffect(() => {
        if (userData.WhenToSolveTheProblem === undefined || userData.WhenToSolveTheProblem === "") {
            setisbuttonDisabled(true); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(false); // Disable the button if both checkboxes are unchecked
        }
    }, [userData.WhenToSolveTheProblem]);
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value })
    };
    // console.log(userData)
    return (
        <>
            <div className={`${styles.StepOuterDiv} ${styles.Step13OuterDiv}`}>
                <h1>When are you looking to solve the problem?</h1>
                {/* <input onChange={handleChange} value={userData["userEmail"] || ""} name="userEmail" placeholder='Email*' /> */}
                <div className={styles.optionsDiv}>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="WhenToSolveTheProblem"
                                value="Immediately"
                                checked={selectedOption === "Immediately"}
                                onChange={handleOptionChange}
                            /> Immediately
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="WhenToSolveTheProblem"
                                value="In the next 2 weeks"
                                checked={selectedOption === "In the next 2 weeks"}
                                onChange={handleOptionChange}
                            />In the next 2 weeks
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="WhenToSolveTheProblem"
                                value="2-4 weeks"
                                checked={selectedOption === "2-4 weeks"}
                                onChange={handleOptionChange}
                            /> 2-4 weeks
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="WhenToSolveTheProblem"
                                value="4-6 weeks"
                                checked={selectedOption === "4-6 weeks"}
                                onChange={handleOptionChange}
                            /> 4-6 weeks
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="WhenToSolveTheProblem"
                                value="6 weeks+"
                                checked={selectedOption === "6 weeks+"}
                                onChange={handleOptionChange}
                            /> 6 weeks+
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="WhenToSolveTheProblem"
                                value="Never"
                                checked={selectedOption === "Never"}
                                onChange={handleOptionChange}
                            />  Never
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="WhenToSolveTheProblem"
                                value="I'm just trying"
                                checked={selectedOption === "I'm just trying"}
                                onChange={handleOptionChange}
                            />  I'm just trying
                        </label>
                    </div>
                </div>
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </>
    )
}

export default Step13
