import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

const Step9 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext)
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    useEffect(() => {
        // Check if either "Product" or "Service" is checked
        if (userData.AboutBusiness === undefined || userData.AboutBusiness === "") {
            setisbuttonDisabled(true); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(false); // Disable the button if both checkboxes are unchecked
        }
    }, [userData.AboutBusiness]);
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value })
    }

    return (
        <>
            <div className={`${styles.StepOuterDiv} ${styles.Step9OuterDiv}`}>
                <h1>Briefly describe your business, what do you sell, to who and at what price point?</h1>
                <textarea rows="9" onChange={handleChange} value={userData["AboutBusiness"] || ""} name="AboutBusiness" placeholder='' />
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </>
    )
}

export default Step9
