import React, { useState, useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import styles from "../Accordion/Accordion.module.css";

const faqs = [
  {
    id: 1,
    header:
      "Why do I need to pay $70 as upfront at the beginning of the work because I haven't seen my design yet?",
    text: `<p>Yes. with our company to proceed further in beginning there is the small account setup fee of 70$ in which you will be getting one free domain name of your choice for 2 years of ownership and that is to begin the work to make assure that you are our client and we can pass your details to our designing department. Only to set up an account and you can ask for unlimited changes till the time you are satisfy with the sample design.</p>
        <p>This $70 includes:</p>
        <ul style="color:black">
            <li >Account setup fee: $70 /-</li>
            <li>Applicable at the start of the work. Includes:
            <ul>
            <li>Free (Google friendly) domain name (.com.au)</li>
            <li>Sample design</li>
            <li>Unlimited changes on the sample design</li>
            </ul>
            </li>
           
        </ul>
        <p><b>Note : In a worst case scenario if you don’t like the design after making number of changes and you want to quit our company make sure that you are not losing that $70 which you have paid us in beginning as an account setup fee. You will be walking away with the domain name that we will register for your business for 2 years.</b></p>`,
  },
  {
    id: 2,
    header: "What are the ongoing maintenance costs to run my website?",
    text: `<p >There are two main ongoing costs to keep your website live on the Internet:</p><p style="color:black">
        <b>Hosting</b>: Every website on the Internet needs to be ‘hosted’ on a server somewhere. It’s like paying a monthly "rent” for your website to “live” on the Internet. Without it, your website would not exist. Our fee includes hosting, editing access and ongoing support. We believe our fee is the best value in Australia among web designers.</p>
        <p style="color:black"><b style="color:black">Domain Name Renewal:</b> Each year, your domain name will require renewal. If we purchased this on your behalf, we will simply renew it for you at cost each year ($70 for 12 months) as part of our ongoing website development service.</p>`,
  },
  {
    id: 3,
    header: "Can I use a different hosting company?",
    text: `<p style="color:black">Yes. Hosting with our company is fully optional so, once your website is complete then we will provide you all your files and you can host your website with other company that we have designed for your business.</p>`,
  },
  {
    id: 4,
    header: "Who owns the website after it's created?",
    text: `<p style="color:black">You do. We provide you with complete handover instructions to own your website and we will simply manage your domain name renewal (if applicable) and hosting for the life of the website. Our service is made to save your time and remove any hassles.</p>`,
  },
  {
    id: 5,
    header: "Is there a contract or minimum period with your monthly hosting?",
    text: `<p style="color:black">Never. There are no contracts with our web design service or our ongoing monthly fees so you can cancel your website anytime.</p>`,
  },
  {
    id: 6,
    header:
      "Can I make changes to my website after it's published? How much does it cost to make changes",
    text: `<p style="color:black">Of course you can!  We will provide you with easy self-management instructions to take over editing of the website, it’s very easy to get the hang of it. Our friendly support team is always on hand to support you through self-management if you get stuck along the way, so we'll never leave you stranded!</p> ​`,
  },
  {
    id: 7,
    header: "How long will it take for my website to go live?",
    text: `<p>We can have your draft website ready within 15 business days. Any delays to this will depend on how quickly you can send Murphy’s Technology your initial information and the time it takes you to review your website. Once you have approved your website, we will publish your website to go live and it will be publicly viewable on your domain name.
        </p>
        <p>Please note that your website will not come up in the search results just yet, because Google takes between 4 days to 4 weeks to discover a new website for ranking purposes. This is a very normal process, so just need to keep an eye on it until you see it show up.
        </p>​`,
  },
  {
    id: 8,
    header: "Do you offer ecommerce websites?",
    text: `<p style="color:black">We offer unlimited eCommerce service up to unlimited eCommerce products.</p> ​`,
  },
  {
    id: 9,
    header: "I don’t like my current website, can you provide assistance?",
    text: `<p style="color:black">Yes we can, as part of our web design service. Keep your domain name and when your new website is up and running with us, you simply cancel your old hosting. Contact us today so we can help you re-launch your website with our signature website development service.</p> ​`,
  },
  {
    id: 10,
    header: "I already have a domain name, can I use this for my website?",
    text: `<p style="color:black">​Yes, this is no problem at all. We will assist you in ensuring your domain settings point to your new website as part of our web design service.</p>​`,
  },
  {
    id: 11,
    header: "What is search engine optimization (SEO)?",
    text: `<p style="color:black">​Search engine optimization (SEO) is the process of ranking your website to show up on Google results for various keyword searches related to your business.</p>​`,
  },
  {
    id: 12,
    header: "What is better, a com.au or a .com domain? Does it matter?",
    text: `<p style="color:black">​Google geo-targets its results to rank Australian extensions (.com.au) higher for Australian searches because they are more relevant. If your audience is international, .com domain can still be relevant for wider reach.</p>​`,
  },
  {
    id: 13,
    header: "Are there any emails included with my website?",
    text: `<p style="color:black">​Yes. We do provide you a webmail account which means the email for your website such as info@yourdomain.com. 
        To be more professional in the busines.</p>​`,
  },
  {
    id: 14,
    header: "Will my website be available to all apple and android devices?",
    text: `<p style="color:black">Yes, all of our websites are smartphone and tablet-friendly and standard with our website development service. </p>​​`,
  },
  {
    id: 15,
    header: "What pictures can I use on my website?",
    text: `<p style="color:black">You can use any images you own or have permission to use, we will ask you to send these to Murphy’s Technology during the process. If you do not have any photos, we will source relevant and high quality stock images to bring your website to life..</p>​`,
  },
  {
    id: 16,
    header: "Do you offer a one page website design service?",
    text: `<p style="color:black">Yes we do, please contact us today to arrange this service. One page web design is popular for taking the first step and we offer a discounted website development service for such needs.</p>​`,
  },
  {
    id: 17,
    header: "Do your prices include GST?",
    text: `<p style="color:black"> Yes, in addition to the prices you see, GST is charged per Australian taxation law requirements.</p>​`,
  },
  {
    id: 18,
    header: "What is Google my business or Google places?",
    text: ` <p style="color:black"> Google My Business is a Google service which allows you have a Google Places listing
        (a "pin" on the Google map) to give your business name and map a prominent display onmozohc
         Page 1 of Google when users search your business name. This is complemented with a Google
          + page that is connected to the listing. If you would like Murphy’s Technology to create 
          this for you, we can do it for a one-off fee of $100 and you will need a registered address
           that we can submit for your business (PO Boxes are not accepted by Google).. </p>​`,
  },
  {
    id: 19,
    header:
      "​I want to change my current website, add more pages and change the look. Can you help me?",
    text: `<p style="color:black">Yes we can, and although different in need from our standard web design service,
        we offer an excellent website re-design service that offers website development with results! Keep your domain name and when your new website is up and running with us, you simply cancel your old hosting.
        Contact us today so we can help you re-launch your website..</p>​`,
  },
  {
    id: 20,
    header: "​Why your websites are so cheap compared to others?",
    text: `<p style="color:black">We believe everyone should have online presence in today’s world. Just because you don't have enough capital doesn't mean you cannot be online. We have different packages according to the needs and requirement of the customers.​</p>`,
  },
  {
    id: 21,
    header: "​Why is SEO important?",
    text: `<p style="color:black">​
        Say you’re looking for a Limousine in Sydney. If you wanted to find Paul’s Limousine Sydney, you might type “Paul Limousine Sydney” into google.com.au. It comes up first because it’s an extremely close match for the search term. However, if you don’t know the name of the motel, and just type “motel Sydney”, Greenwich Inn comes up first – Paul Limousine doesn’t even appear on the first page. A ranking in the #1 position on the first page of Google for a keyword will often get approximately 40% of traffic coming to their website... this is why it's a highly coveted position! </p><p>You want as many people as possible to find your website, even if they haven’t heard of you. It's generally accepted that higher-ranking websites are more trustworthy and informative. SEO is the key to making this happen for your business’ online presence.</p><p>There are two parts to SEO - on-page optimization and off-page optimization.  Successful search engine optimization involves continued efforts on both of these components. If you want anyone to find your website without paying for advertising, your success depends on SEO.</p>.​`,
  },
  {
    id: 22,
    header: "​How does SEO work?",
    text: `<p style="color:black">​
        There are many factors that search engines take into account. Google has previously indicated that their algorithm assesses around 200 variables when determining a website ranking!</p><p>Some of the basic ways to maintain a website that appeals to both users and search engines include:</p><p>Having fresh, relevant information and continuously updated content on your website</p><p>
        Using relevant, descriptive keywords for your titles, copy, URLs, and images.</p><p>Creating content that other people want to share and link back to - building a natural link network around your website. </p><p><strong>IT TAKES 2-6 WEEKS FOR GOOGLE TO INDEX A NEW WEBSITE TO APPEAR IN SEARCH RESULTS</strong></p><p>It’s important to note that it takes significant time for a website to move higher in the ranks and get found by users on the Internet.  New websites will take up to 6 weeks before Google indexes them (i.e. recognizes their existence in the search engines). So it’s best to get your website up as soon as possible to start building your online presence and get your domain to begin ‘ageing’.</p>​`,
  },
];
const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <div className={styles.rcAccordionCard}>
      <div className={styles.rcAccordionHeader}>
        <div
          className={`${styles.rcAccordionToggle} p-3 ${
            active === id ? "active" : ""
          }`}
          onClick={() => handleToggle(id)}
        >
          <h5 className$={styles.rcAccordionTitle}>{header}</h5>
          <div className={` ${styles.rcAccordionIcon}`}>
            {active !== id && <AiOutlinePlus />}
            {active === id && <AiOutlineMinus />}
          </div>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`${styles.rcCollapse} ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className={styles.rcAccordionBody}>
          {/* <p className='mb-0'>{text}</p> */}
          <div
            style={{ color: "#727e8c" }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
    </div>
  );
};
const AccordionFAQ = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <>
      <div className="container-fluid mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="card">
              <div className="card-body">
                {faqs.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionFAQ;
