
import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

const Step16 = ({ handleClick, currentStep, steps }) => {
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    const { userData, setUserData } = useContext(StepperContext);
    const [valueCallNo, setvalueCallNo] = useState(userData.CallNo ? true : false);
    const [valueCallYes, setvalueCallYes] = useState(userData.CallYes ? true : false);

    useEffect(() => {
        // Check if either "CallNo" or "CallYes" is checked
        if (valueCallNo || valueCallYes) {
            setisbuttonDisabled(false); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(true); // Disable the button if both checkboxes are unchecked
        }
    }, [valueCallNo, valueCallYes]);

    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    const handleIconsClickCallNo = () => {
        if (valueCallYes) {
            setvalueCallYes(false)
        }
        setvalueCallNo(!valueCallNo)
    }
    const handleIconsClickCallYes = () => {
        if (valueCallNo) {
            setvalueCallNo(false)
        }
        setvalueCallYes(!valueCallYes)
    }
    const handleChange = (name) => {
        // Determine the new values for both "CallYes" and "CallNo" checkboxes
        let newCallNoValue = valueCallNo;
        let newCallYesValue = valueCallYes;

        if (name === "CallNo") {
            newCallNoValue = true;
            newCallYesValue = false;
        } else if (name === "CallYes") {
            newCallNoValue = false;
            newCallYesValue = true;
        }

        // Update the state and userData object with the new values
        setvalueCallNo(newCallNoValue);
        setvalueCallYes(newCallYesValue);

        setUserData((prevUserData) => ({
            ...prevUserData,
            CallNo: newCallNoValue,
            CallYes: newCallYesValue,
        }));
    };
    console.log(userData)


    return (
        <div className={`${styles.StepOuterDiv} ${styles.Step4OuterDiv}`}>
            <p>Okay {userData.username},  you made it – last question…</p>
            <h1>Do you pinky promise that if you qualify for a phone call, you will show up at the selected time?</h1>

            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <input
                        type='checkbox'
                        id="CallNo"
                        name="CallNo"
                        checked={userData.CallNo === true}
                        onChange={() => handleChange("CallNo")}
                    />
                    <label htmlFor="CallNo">
                        <div className={valueCallNo ? `${styles.icons} ${styles.clicked}` : styles.icons} onClick={() => handleIconsClickCallNo()}>
                            🙅‍♂️
                        </div>
                        <p>No</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input
                        type='checkbox'
                        id="CallYes"
                        name="CallYes"
                        checked={userData.CallYes === true}
                        onChange={() => handleChange("CallYes")}
                    />
                    <label htmlFor="CallYes">
                        <div className={valueCallYes ? `${styles.icons} ${styles.clicked}` : styles.icons} onClick={() => handleIconsClickCallYes()}>
                            👌
                        </div>
                        <p>Yes</p>
                    </label>
                </div>
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Step16;

// import { useContext, useState } from 'react'
// import { StepperContext } from '../contexts/StepperContext'
// import React from 'react'
// import Product from '../../../img/Flaticon/product.png'
// import Tools from '../../../img/Flaticon/support.png'
// import styles from './Content.module.css'

// const Step16 = () => {
//     const [valueProduct, setvalueProduct] = useState(false);
//     const [valueService, setvalueService] = useState(false);

//     const handleInputChange10 = () => {

//     }
//     const { userData, setUserData } = useContext(StepperContext)
//     if (!userData) {
//         return <div>Loading...</div> // or some other fallback behavior
//     }
//     // eslint-disable-next-line
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setUserData({ ...userData, [name]: value })
//     }
//     const handleIconsClickProduct = () => {
//         if (valueService) {
//             setvalueService(false)
//         }
//         setvalueProduct(!valueProduct)
//     }
//     const handleIconsClickService = () => {
//         if (valueProduct) {
//             setvalueProduct(false)
//         }
//         setvalueService(!valueService)
//     }
//     return (
//         <div className={`${styles.StepOuterDiv} ${styles.Step4OuterDiv}`}>
//             <p>Okay {userData.username},  you made it – last question…</p>
//             <h1>Do you pinky promise that if you qualify for a phone call, you will show up at the selected time?</h1>
//             <div className={styles.buttonIcons}>
//                 <div style={{ fontSize: '80px' }} className={valueProduct ? `${styles.icons} ${styles.clicked}` : styles.icons} onClick={() => handleIconsClickProduct()}>
//                     🙅‍♂️
//                 </div>
//                 <input
//                     type="text"
//                     className={styles.spinnerinput}
//                     onChange={handleInputChange10}
//                     name="Service"
//                     value={valueProduct}
//                     style={{ display: 'none' }}
//                 />
//                 <div style={{ fontSize: '80px' }} className={valueService ? `${styles.icons} ${styles.clicked}` : styles.icons} onClick={() => handleIconsClickService()}>
//                     👌
//                 </div>
//                 <input
//                     type="text"
//                     className={styles.spinnerinput}
//                     onChange={handleInputChange10}
//                     name="Service"
//                     value={valueService}
//                     style={{ display: 'none' }}
//                 />
//             </div>
//         </div>
//     )
// }

// export default Step16



