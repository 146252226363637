import { useContext, useState } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import facebook from '../../../img/Flaticon/options/facebook.png'
import adwrods from '../../../img/Flaticon/options/adwords.png'
import google from '../../../img/Flaticon/options/search.png'
import tv from '../../../img/Flaticon/options/tv.png'
import radio from '../../../img/Flaticon/options/microphone.png'
import news from '../../../img/Flaticon/options/newspaper.png'
import other from '../../../img/Flaticon/options/bell.png'
import none from '../../../img/Flaticon/options/no-stopping.png'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';
import { useEffect } from 'react'

const Step5 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext)
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true)

    useEffect(() => {
        // Check if any one of the items is checked and update isbuttonDisabled
        const isAnyItemChecked = Object.values(userData).some((value) => value === true);
        setisbuttonDisabled(!isAnyItemChecked);
    }, [userData])
    // userData
    useEffect(() => {
        // Define an array of field names to check
        const fieldsToCheck = ["facebook", "googleAdwords", "none", "SEO", "Television", "Radio", "Newspaper", "other"]; // Replace with your specific field names

        // Check if any of the specified fields is checked and update isbuttonDisabled
        const isAnyFieldChecked = fieldsToCheck.some((fieldName) => userData[fieldName] === true);
        setisbuttonDisabled(!isAnyFieldChecked);
    }, [userData]);
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }


    const handleChange = (e) => {
        const { name, checked } = e.target;

        // Clone the userData object and update the selected marketing methods
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: checked,
        }));


    };
    return (
        <div className={`${styles.StepOuterDiv} ${styles.Step5OuterDiv}`}>
            <h1>Okay great! What types of marketing are you currently using ?</h1>
            <p>(Select more than one if applicable)</p>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <input
                        type='checkbox'
                        id="facebook"
                        name="facebook"
                        onChange={handleChange}
                        checked={userData.facebook === true}
                    />
                    <label for="facebook">
                        <img src={facebook} alt="face" />
                        <p>Facebook</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input
                        type='checkbox'
                        id="googleAdwords"
                        name="googleAdwords"
                        onChange={handleChange}
                        checked={userData.googleAdwords === true}
                    />
                    <label for="googleAdwords">
                        <img src={adwrods} alt="face" />
                        <p>Google Adwords</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input type='checkbox' id="SEO" checked={userData.SEO === true} name="SEO" onChange={handleChange} />
                    <label for="SEO">
                        <img src={google} alt="face" />
                        <p>SEO</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input type='checkbox' id="Television" checked={userData.Television === true} name="Television" onChange={handleChange} />
                    <label for="Television">
                        <img src={tv} alt="face" />
                        <p>Television</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input type='checkbox' id="Radio" checked={userData.Radio === true} name="Radio" onChange={handleChange} />
                    <label for="Radio">
                        <img src={radio} alt="face" />
                        <p>Radio</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input type='checkbox' id="Newspaper" checked={userData.Newspaper === true} name="Newspaper" onChange={handleChange} />
                    <label for="Newspaper">
                        <img src={news} alt="face" />
                        <p>Newpaper</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input type='checkbox' id="other" checked={userData.other === true} name="other" onChange={handleChange} />
                    <label for="other">
                        <img src={other} alt="face" />
                        <p>Others</p>
                    </label>
                </div>
                <div className={styles.container}>
                    <input type='checkbox' id="none" checked={userData.none === true} name="none" onChange={handleChange} />
                    <label for="none">
                        <img src={none} alt="face" />
                        <p>None</p>
                    </label>
                </div>

            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
            {/* <input onChange={handleChange} value={userData["userEmail"] || ""} name="userEmail" placeholder='Email*' /> */}
        </div>
    )
}

export default Step5
