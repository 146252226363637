import React from 'react'
// import TopBanner from '../../components/TopBanner/TopBanner'
import AccordionFAQ from '../../components/AccodionFAQ/AccordionFAQ'
import styles from './FAQ.module.css'
import GetAQuote from '../../components/GetAQuote'
import TopBanner2 from '../../components/TopBanner/TopBanner2'
import { Helmet } from "react-helmet";
const FAQ = () => {
    return (
        <>
        <Helmet>
        <meta charset="utf-8" />
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
        <div>
            {/* <TopBanner name="FAQ" /> */}
            <TopBanner2 name="FAQ" />
            <div className={styles.AccordionFAQ}>
                <h4>Here are answers to all your possible concerns.</h4>
                <h1>FREQUENTLY</h1><h1> ASKED QUESTIONS</h1>
                <div className={styles.glass}>
                    <AccordionFAQ />
                </div>
            </div>
            <GetAQuote />
        </div>
        </>
    )
}

export default FAQ
