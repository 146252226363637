import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

const Step15 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext)
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    useEffect(() => {
        // Check if either "Product" or "Service" is checked
        if (userData.PathToUS === undefined || userData.PathToUS === "" || userData.PathToUS === "Please select one*") {
            setisbuttonDisabled(true); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(false); // Disable the button if both checkboxes are unchecked
        }
    }, [userData.PathToUS]);
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value })
    }

    // console.log(userData)
    return (
        <>
            <div className={`${styles.StepOuterDiv} ${styles.Step6OuterDiv}`}>
                <h1>Where did you FIRST hear about us?</h1>
                <p>Seriously, we are curious :) Please answer this simple question and help us get better.</p>
                <select className="form-select" id="PathToUS" value={userData["PathToUS"] || ""} name="PathToUS" onChange={(e) => handleChange(e)}>
                    <option>Please select one*</option>
                    <option value="Search Engine">Search Engine (Google,Bing)</option>
                    <option value="Google Ads ">Google Ads </option>
                    <option value="Facebook Ads">Facebook Ads</option>
                    <option value="Youtube Ads">Youtube Ads</option>
                    <option value="Other paid social media advertising">Other paid social media advertising</option>
                    <option value="Facebook post/group">Facebook post/group</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Instagram Post/Story">Instagram Post/Story</option>
                    <option value="Other social media">Other social media</option>
                    <option value="Email">Email</option>
                    <option value="TV">TV</option>
                    <option value="Newspaper">Newspaper</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </>
    )
}

export default Step15

