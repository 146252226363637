import React, { useState, useRef } from 'react'
// import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './Career.module.css'
import { FaRegHandshake } from 'react-icons/fa'
import { AiOutlineComment } from 'react-icons/ai'
import { BiMoneyWithdraw } from 'react-icons/bi'
// import SignUp from '../../img/Flaticon/handshake.webp'
// import Earn from '../../img/Flaticon/earning.webp'
// import Dialogue from '../../img/Flaticon/dialogue.webp'
import Accordion from '../../components/Accordion/Accordion'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'
import emailjs from "@emailjs/browser";
import GetAQuote from '../../components/GetAQuote'
import TopBanner2 from '../../components/TopBanner/TopBanner2'
import { Helmet } from "react-helmet";
function Video({ src }) {

    return (
        <div>
            {/* <video className={styles.Video} src={src} controls autoPlay /> */}
            <div style={{ width: "100%", height: "0px", position: "relative", paddingBottom: "56.250%" }}>

                <iframe
                    title='video1'
                    src="https://streamable.com/e/48p9fc?autoplay=1"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    allow="autoplay"
                    controls="0"
                    style={{ borderRadius: '10px', width: "100%", height: "95%", position: "absolute", left: "0px", top: "0px", overflow: "hidden" }}>
                </iframe>
            </div>
        </div>
    );
}

const Career = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_6htw1pb",
                "template_9pidutl",
                form.current,
                "tYGcFX6keIbKGRLPd"
            )
            .then(
                (result) => {
                    // console.log(result.text);
                    alert("Message has been sent!");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    const handleSubmitEmail = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        sendEmail(e);
        form.current.reset();
    };
    // eslint-disable-next-line
    const [src, setSrc] = useState('https://streamable.com/48p9fc');

    return (
        <> 
        <Helmet>
        <meta charset="utf-8" />
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
        <div>
            {/* <TopBanner name="Affiliates" /> */}
            <TopBanner2 name="Affiliates" />
            <div className={styles.HeroSection}>
                <div className={styles.LeftSection}>
                    <ScrollAnimation>
                        <h4>Don't Miss The Chance To Earn From Every Sale</h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>Join the</h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>Murphys Tech's</h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>affiliate</h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>program</h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <p>
                            Earn $$$ from each sale by joining the murphy's affiliate program.
                        </p>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <button className={styles.btn} id={styles.btn1} ><a href="#register" style={{ scrollBehavior: "smooth", color: 'white' }} >Register & Earn</a></button>
                    </ScrollAnimation>

                </div>
                <div className={styles.RightSection}>
                    <Video src={src} />
                </div>
            </div>
            <div className={styles.CardSection}>
                <div className={styles.Card}>
                    <div className={styles.rectangle}></div>
                    <div className={styles.rectangleTop}></div>
                    {/* <img src={SignUp} alt="sign" /> */}
                    <div style={{ fontSize: '60px' }}>
                        <FaRegHandshake />
                    </div>
                    <h2 style={{ margin: '0' }}>Sign up</h2>
                    <p>Join the group of individuals, publications, bloggers, and innovators who are already making money with the Murphy's Technology Affiliates Program.</p>
                </div>
                <div className={styles.Card}>
                    <div className={styles.rectangle}></div>
                    <div className={styles.rectangleTop}></div>
                    {/* <img src={Dialogue} alt="sign" /> */}
                    <div style={{ fontSize: '60px' }}>
                        <AiOutlineComment />
                    </div>
                    <h2 style={{ margin: '0' }}>Recommend</h2>
                    <p>Share our services with your audience. We have customised linking tools for individual bloggers and social media influencers..</p>
                </div>
                <div className={styles.Card}>
                    <div className={styles.rectangle}></div>
                    <div className={styles.rectangleTop}></div>
                    {/* <img src={Earn} alt="sign" /> */}
                    <div style={{ fontSize: '60px' }}>
                        <BiMoneyWithdraw />
                    </div>
                    <h2 style={{ margin: '0' }}>Earn</h2>
                    <p>Earn up to 10% in affiliate commissions from our qualifying services. Lets grow together !!!!.</p>
                </div>
            </div>
            <div className={styles.Form} id="register" >
                <ScrollAnimation>
                    <h4>Enquire today to start earning.</h4>
                </ScrollAnimation>
                <ScrollAnimation>
                    <h1>Become An Affiliate</h1>
                </ScrollAnimation>

                <form ref={form} onSubmit={(e) => handleSubmitEmail(e)}>
                    <div className={styles.gridContainer} >
                        <div className={styles.column}>
                            <label>First Name</label>
                            <input type="text" name="Fname" placeholder='First Name' />
                        </div>
                        <div className={styles.column}>
                            <label>Last Name</label>
                            <input type="text" name="Lname" placeholder='Last Name' />
                        </div>
                        <div className={styles.column}><label>Email</label>
                            <input type="email" name="Email" placeholder='example@example.com' /></div>
                        <div className={styles.column}>
                            <label>Phone Number</label>
                            <input type="text" name="Phone" placeholder='0400 000 000' />
                        </div>
                        <div className={`${styles.column} ${styles.span2}`}>
                            <label>Are you a business or individual?</label>
                            <select name="BusinessOrIndividual" id="BusinessOrIndividual">
                                <option value="Business">Business</option>
                                <option value="Individual">Individual</option>
                            </select>
                        </div>
                        <div className={`${styles.column} ${styles.span2}`}>
                            <label>Which products are you interested in offering?</label>
                            <div className={styles.gridContainerSelect}>
                                <div>
                                    <input type="checkbox" id="vehicle1" name="Website Design/Development" />
                                    {/* value="Website Design/Development" */}
                                    <label for="Website Design/Development">Website Design/Development</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="vehicle1" name="Website Hosting/Domain Names" />
                                    {/* value="Website Hosting/Domain Names" */}
                                    <label for="Website Hosting/Domain Names">Website Hosting/Domain Names</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="vehicle1" name="Seo Service" />
                                    {/* value="Seo Service" */}
                                    <label for="Seo Service">Seo Service</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="Other" name="Other" />
                                    {/* value="Other" */}
                                    <label for="vehicle1">Other</label>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.column} ${styles.span2}`}>
                            <label>Message</label>
                            <textarea rows='10' name="Information" placeholder='Any other information....'></textarea>
                        </div>
                        <div className={`${styles.column} ${styles.span2}`}>
                            <button className={styles.btn} id={styles.btn1} type='submit'>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className={styles.FAQSection}>
                <div className={styles.glass}>
                    <ScrollAnimation>
                        <h4>These are common questions we get asked.</h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>Frequently Asked</h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>Questions</h1>
                    </ScrollAnimation>
                    <div className={styles.accordian}>
                        <Accordion />
                    </div>
                </div>
            </div>
            <div className={styles.Questions}>
                <div>
                    <h2>Still have questions?</h2>
                    <p>If you have other questions which weren't answered above, or want to get the process started</p>
                </div>
                <div>
                    <button className={styles.btn} id={styles.btn1} ><a style={{ color: 'white' }} href="tel:0272544827">Book discovery call</a></button>
                </div>

            </div>
            <GetAQuote />
        </div>
        </>
    )
}

export default Career
