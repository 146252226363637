import React, { useEffect, useState } from 'react'
import styles from './GetAQuote.module.css'
import { useNavigate } from 'react-router'
// import { AiOutlineHeart } from 'react-icons/ai'
import Lottie from "lottie-react";
import groovyWalkAnimation from '../img/Animation5.json';

const GetAQuote = () => {
    const navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showicon, setShowIcon] = useState(false)
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        const updateShowIcon = () => {
            if (windowWidth < 750) {
                setShowIcon(true);
            } else {
                setShowIcon(false);
            }
        };

        // Add event listener on component mount
        window.addEventListener('resize', handleWindowResize);

        // Update showicon based on window width
        updateShowIcon();

        // Clean up by removing event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [windowWidth]);



    return (
        <div className={styles.outerDiv}>
            <div className={styles.text}>
                <div className={styles.leftdiv}>
                    <p>Like What</p>
                    <p> You See?</p>
                </div>
                <div className={styles.middlediv}>
                    {showicon && <div className={styles.icon}>
                        {/* <AiOutlineHeart /> */}
                        <Lottie animationData={groovyWalkAnimation} loop={true} className={styles.iconicon} />
                    </div>}
                    <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                        <h1>WE WOULD </h1>
                        {!showicon && <Lottie animationData={groovyWalkAnimation} loop={true} style={{ width: '40px', height: '40px', display: 'inline-block', margin: '0 5px 0 5px' }} />}
                        <h1>{showicon && <span style={{ margin: '0 5px 0 5px', color: 'white' }}> LOVE </span>}  TO<span>PARTNER</span> WITH YOU</h1>
                    </div>

                </div>
            </div>
            <div className={styles.rightdiv}>
                <button onClick={() => navigate('/contact')}>Request a Quote</button>
            </div>
        </div>
    )
}

export default GetAQuote
