import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs'

const Step3 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext)
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true)

    useEffect(() => {
        if (userData.userEmail === undefined || userData.userEmail === "" || !isValidEmail(userData.userEmail)) {
            setisbuttonDisabled(true);
        } else {
            setisbuttonDisabled(false);
        }
    }, [userData.userEmail]); // Add userData.username as a dependency
    function isValidEmail(email) {
        // Regular expression for a basic email format validation
        const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(email);
    }
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value })
    }
    // console.log(userData)
    return (
        <div className={`${styles.StepOuterDiv} ${styles.Step3OuterDiv}`}>
            <h1 className={styles.Step3h1}>Awesome! What's your email {userData.username}?</h1>
            <input className={styles.Step3input} onChange={handleChange} value={userData["userEmail"] || ""} name="userEmail" placeholder='Email*' />
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Step3
