import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Banner.module.css'
import './marquee.css'
import ScrollAnimation from '../Animation/ScrollAnimation'
import Marquee from 'react-fast-marquee'
import img1 from '../../img/Slider/Slider-1.webp'
import img2 from '../../img/Slider/Slider-2.webp'
import img3 from '../../img/Slider/Slider-3.webp'
import img4 from '../../img/Slider/Slider-4.webp'
import img5 from '../../img/Slider/Slider-5.webp'
import img6 from '../../img/Slider/Slider01.png'
import img7 from '../../img/Slider/Slider02.png'
import img8 from '../../img/Slider/Slider03.png'
import img9 from '../../img/Slider/Slider04.png'
import img10 from '../../img/Slider/Slider05.png'

import CombineAnimation from '../../components/Animation/CombineAnimation'
// import star from '../../img/Flaticon/setting.png'

const Banner = () => {
    const [selectedPlan, setselectedPlan] = useState('Value')
    return (
        <div className={styles.Pricing}>
            <div className={styles.Heading}>
                {/* <img src={star} alt="start" /> */}
                <ScrollAnimation>
                    <h4>OUR PORFOLIO</h4>
                </ScrollAnimation>
            </div>
            <ScrollAnimation>
                <h1>BRAND WEBSITE AND </h1>
            </ScrollAnimation>
            <ScrollAnimation>
                <h1>ADVERTISING SHOWCASE </h1>
            </ScrollAnimation>
            <Marquee className="marquee-container-image">
                <Link to="/process">
                    <div
                        className={styles.imageContainer}
                        style={{
                            backgroundImage: `url(${img1})`,
                        }}
                    ></div>
                </Link>
                <Link to="/process">
                    <div
                        className={styles.imageContainer}
                        style={{
                            backgroundImage: `url(${img2})`,
                        }}
                    ></div>
                </Link>

                <Link to="/process">
                    <div
                        className={styles.imageContainer}
                        style={{
                            backgroundImage: `url(${img3})`,
                        }}
                    ></div>
                </Link>

                <Link to="/process">
                    <div
                        className={styles.imageContainer}
                        style={{
                            backgroundImage: `url(${img4})`,
                        }}
                    ></div>
                </Link>
                <Link to="/process">
                    <div
                        className={styles.imageContainer}
                        style={{
                            backgroundImage: `url(${img5})`,
                        }}
                    ></div>
                </Link>
            </Marquee>
            <Marquee
                className="marqueeContainer"
                style={{ marginTop: '10vh' }}
                direction="right"
                speed={70}
            >
                <div className={styles.imageContainer1}>
                    <img src={img6} alt="" />
                </div>
                <div className={styles.imageContainer1}>
                    <img src={img7} alt="" />
                </div>
                <div className={styles.imageContainer1}>
                    <img src={img8} alt="" />
                </div>
                <div className={styles.imageContainer1}>
                    <img src={img9} alt="" />
                </div>
                <div className={styles.imageContainer1}>
                    <img src={img10} alt="" />
                </div>
            </Marquee>
        </div>
    )
}

export default Banner
