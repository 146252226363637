import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import styles from './TopBanner2.module.css'
import AboutBanner from '../../img/Background/Component26.webp'
import ServiceBanner from '../../img/Background/Component46.webp'
import AffiliatesBanner from '../../img/Background/Component28.webp'
import ProcessBanner from '../../img/Background/Component27.webp'
import ContactBanner from '../../img/Background/ComponentCopy.webp'
import CareerBanner from '../../img/Background/Component29.webp'
import { IconContext } from 'react-icons'
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md'

const TopBanner2 = (props) => {
    const navigate = useNavigate();
    const [banner, setBanner] = useState(AboutBanner);
    useEffect(() => {
        switch (props.name) {
            case "About": setBanner(AboutBanner)
                break;
            case "Services": setBanner(ServiceBanner)
                break;
            case "Affiliates": setBanner(AffiliatesBanner)
                break;
            case "Process": setBanner(ProcessBanner)
                break;
            case "Contact": setBanner(ContactBanner)
                break;
            case "Career": setBanner(CareerBanner)
                break;
            default:
                break;
        }
        // if (props.name === "About") {
        //     setBanner(AboutBanner)
        // }
    }, [props.name])
    return (
        <div className={styles.topBanner}>
            <img className={styles.backgroundimage} src={banner} alt='banner'></img>

            {/* style={{ backgroundImage: `url(${banner})` }} */}
            <div className={styles.BannerHeading} >
                <h1>{props.name}</h1>
                <div className={styles.BannerSubHeading}>
                    <h3 onClick={() => navigate('/')}>Home</h3>
                    <IconContext.Provider value={{ size: '26px' }}>
                        <MdOutlineKeyboardDoubleArrowRight />
                    </IconContext.Provider >
                    <h3 onClick={() => navigate(props.namelink)}>{props.name}</h3>
                    {props.innername &&
                        <>
                            <IconContext.Provider value={{ size: '26px' }}>
                                <MdOutlineKeyboardDoubleArrowRight />
                            </IconContext.Provider >
                            <h3>{props.innername}</h3>
                        </>
                    }
                </div>
            </div>

        </div>
    )
}

export default TopBanner2
