import React from 'react'
import styles from './Footer.module.css'
import Logo from '../img/Logos/primary_logo.png'
import Australia from '../img/Logos/australia1.webp'
import Macfree from '../img/Logos/mcafee.webp'
import SecureSSL from '../img/Logos/securessl.webp'
import Privacy from '../components/Privacy.pdf'
import Terms from '../components/TermsandCondition.pdf'
import Refund from '../components/RefundPolicy.pdf'

import component from '../img/Logos/Component6.webp'
import * as FaIcons from 'react-icons/fa6'
import * as IoIcons from 'react-icons/io5'
import * as IoIconss from 'react-icons/io'
import { useLocation } from 'react-router'

const Footer = () => {
    const location = useLocation()
    const handleDownloadPrivacyPDF = () => {
        const link = document.createElement('a')
        link.href = Privacy
        link.download = 'Privacy.pdf' // You can specify the desired filename here
        link.click()
    }
    const handleDownloadTermsPDF = () => {
        const link = document.createElement('a')
        link.href = Terms
        link.download = 'TermsandCondition.pdf' // You can specify the desired filename here
        link.click()
    }
    const handleDownloadRefundPDF = () => {
        const link = document.createElement('a')
        link.href = Refund
        link.download = 'RefundPolicy.pdf' // You can specify the desired filename here
        link.click()
    }
    let locationString1 = location.pathname.slice(1)
    const shouldShowNavbar = locationString1.includes('StrategySession')
    return (
        <>
            {!shouldShowNavbar && (
                <div className={styles.Footer}>
                    <div className={styles.container}>
                        <div className={styles.IntroSection}>
                            <img src={Logo} alt="logo" />
                            <p>
                                ©Except as permitted by the copyright law
                                applicable to you, you may not reproduce or
                                communicate any of the content on this website,
                                including files downloadable from this website,
                                without the permission of the copyright owner.
                            </p>
                            <p>ACN : 628 755 055</p>
                            <p>ABN : 97 628 755 055</p>
                            <div className={styles.pictures}>
                                <img src={Australia} alt="aus" />
                                <img src={Macfree} alt="mac" />
                                <img src={SecureSSL} alt="securessl" />
                            </div>
                        </div>
                        <div className={styles.LeftSection}>
                            <h2>Quick Links</h2>
                            <div>
                                <a href="/">Home</a>
                            </div>
                            <div>
                                <a href="/about">About </a>
                            </div>
                            <div>
                                <a href="/services">Services</a>
                            </div>
                            <div>
                                <a href="/contact">Contact</a>
                            </div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Refund%20Policy.pdf"
                                    target="_blank"
                                >
                                    Refund Policy
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Terms%20and%20Condition.pdf"
                                    target="_blank"
                                >
                                    Terms and Conditions
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Privacy.pdf"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                        <div className={styles.RightSection}>
                            <div>
                                <h2>Contact</h2>
                                <div className={styles.contactDetails}>
                                    <FaIcons.FaLocationDot />
                                    <div>
                                        <p style={{ margin: '0' }}>
                                            Unit 46 162/170 Parramatta Road,
                                        </p>
                                        <p style={{ margin: '0' }}>
                                            {' '}
                                            Homebush NSW 2140
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.contactDetails}>
                                    <IoIcons.IoCallSharp />
                                    <p>
                                        <a href="tel:02 7254 4827">
                                            02 7254 4827
                                        </a>{' '}
                                        (MAIN LINE)
                                        <br />
                                        <a href="tel:0485 931 114">
                                            0485 931 114
                                        </a>{' '}
                                        (HOT LINE)
                                    </p>
                                </div>
                                {/* <div className={styles.contactDetails}>
                                    <IoIcons.IoCallSharp />
                                    <p>
                                        <a href="tel:0485 931 114">
                                            0485 931 114
                                        </a>{' '}
                                        (HOT LINE)
                                    </p>
                                </div> */}
                                <div className={styles.contactDetails}>
                                    <IoIconss.IoMdMail />
                                    <p>
                                        <a href="mailto:info@murphystechnology.com">
                                            info@murphystechnology.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className={styles.Services}>
                                <h2>Trending Platforms</h2>
                                <img src={component} alt="services" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.copyright}>
                        © {new Date().getFullYear()} Murphys Technology Pty Ltd.
                        All Rights Reserved.
                    </div>
                </div>
            )}
        </>
    )
}

export default Footer
