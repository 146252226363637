import { useContext, useEffect, useState } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs'

const Step2 = ({ handleClick, currentStep, steps }) => {

    const { userData, setUserData } = useContext(StepperContext)
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true)

    useEffect(() => {
        if (userData.username === undefined || userData.username === "") {
            setisbuttonDisabled(true);
        } else {
            setisbuttonDisabled(false);
        }
    }, [userData.username]); // Add userData.username as a dependency

    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value })
    }

    return (
        <div className={`${styles.StepOuterDiv} ${styles.Step2OuterDiv}`}>
            <h1>Okay, we’ll make this quick and painless (pinky promise). But before we start - we don't wanna be rude...So what’s your name?</h1>
            <input onChange={handleChange} value={userData["username"] || ""} name="username" placeholder='Enter Your Name' />
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Step2
