import React, { useState } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

import styles from './Home/HeroSection.module.css'

const TextSpan = ({ children }) => {
    const [isPlaying, setisPlaying] = useState(false);
    const controls = useAnimationControls();
    const rubberband = () => {
        controls.start({
            transform: [
                "scale3d(1,1,1)",
                "scale3d(1.4,.55,1)",
                "scale3d(.75,1.25,1)",
                "scale3d(1.25,.85,1)",
                "scale3d(.9,1.05,1)",
                "scale3d(1,1,1)",
            ],
            // transition: {
            //     times: [0, .4, .6, .7, .8, .9]
            // }
        })

    }

    return (
        <motion.h1
            animate={controls}
            onMouseOver={() => {
                if (!isPlaying) {
                    rubberband()
                }
            }}
            className={styles.spandiv}
            onAnimationComplete={() => setisPlaying(false)}
        >
            {children}
        </motion.h1>
    )
}

export default TextSpan
