import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './FeatureList.module.css' // Adjust the import path as necessary

const features = [
    {
        title: 'Custom Strategies & Tactics',
        description: 'Our experts know what will work for you and your brand.',
    },
    {
        title: 'Road Map & Goal Timeline',
        description: 'Your goals and how to achieve them, clearly mapped out.',
    },
    {
        title: 'Growth Trajectory',
        description:
            'We don’t set and forget – we’ll show you how to sustain that growth.',
    },
    {
        title: 'Competitor Intelligence',
        description: 'Everything we do is backed by research and data.',
    },
    {
        title: 'ROI & Investment Options',
        description: 'Solutions that work for you – tailored to your business.',
    },
    {
        title: '10x Your Business Success',
        description:
            "We'll teach you how to maintain that progress rather than just setting.",
    },
]

const FeatureList = () => {
    return (
        <div className={styles.featureList}>
            {features.map((feature, index) => (
                <div key={index} className={styles.featureItem}>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className={styles.featureIcon}
                    />
                    <div className={styles.featureText}>
                        <h3 className={styles.featureTitle}>{feature.title}</h3>
                        <p className={styles.featureDescription}>
                            {feature.description}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FeatureList
