import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'

const Graphics = () => {
    return (
        <div>
            <TopBanner namelink='/services' name="Services" innername="Graphic Design and Branding" />
        </div>

    )
}

export default Graphics
