import { createContext, useState } from "react";

export const StepperContext = createContext(null);

export const StepperContextProvider = ({ children }) => {
    const [userData, setUserData] = useState({});

    return (
        <StepperContext.Provider value={{ userData, setUserData }}>
            {children}
        </StepperContext.Provider>
    );
}