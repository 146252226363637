import React, { useState, useEffect } from 'react'
// import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './Services.module.css'
import { BsArrowRightCircle } from 'react-icons/bs'
import photo1 from '../../img/Background/banner-bg-scaled.webp'
import photo2 from '../../img/Background/background2.webp'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'
import GetAQuote from '../../components/GetAQuote'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import TopBanner2 from '../../components/TopBanner/TopBanner2'
import { Helmet } from 'react-helmet'
import FeatureList from '../Services/FeatureList'
import styles1 from '../../components/Home/AboutSection.module.css'
// import GetAQuote from '../../components/GetAQuote'
const Services = () => {
    const [isHoveredWeb, setIsHoveredWeb] = useState(false)
    const [isHoveredDesign, setIsHoveredDesign] = useState(false)
    const [isHoveredMarket, setIsHoveredMarket] = useState(false)
    const [isHoveredMobile, setIsHoveredMobile] = useState(false)
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    const handleMouseEnterWeb = () => {
        if (viewportWidth > 1000) {
            setIsHoveredWeb(true)
        }
    }

    // eslint-disable-next-line
    const handleWebClick = () => {
        if (viewportWidth <= 1000) {
            setIsHoveredWeb(!isHoveredWeb)
        }
    }
    // eslint-disable-next-line
    const handleDesignClick = () => {
        if (viewportWidth <= 1000) {
            setIsHoveredDesign(!isHoveredDesign)
        }
    }
    // eslint-disable-next-line
    const handleMarketClick = () => {
        if (viewportWidth <= 1000) {
            setIsHoveredMarket(!isHoveredMarket)
        }
    }
    // eslint-disable-next-line
    const handleMobileClick = () => {
        if (viewportWidth <= 1000) {
            setIsHoveredMobile(!isHoveredMobile)
        }
    }
    const handleMouseLeaveWeb = () => {
        if (viewportWidth > 1000) {
            setIsHoveredWeb(false)
        }
    }
    const handleMouseEnterDesign = () => {
        if (viewportWidth > 1000) {
            setIsHoveredDesign(true)
        }
    }
    const handleMouseLeaveDesign = () => {
        if (viewportWidth > 1000) {
            setIsHoveredDesign(false)
        }
    }
    const handleMouseEnterMarket = () => {
        if (viewportWidth > 1000) {
            setIsHoveredMarket(true)
        }
    }
    const handleMouseLeaveMarket = () => {
        if (viewportWidth > 1000) {
            setIsHoveredMarket(false)
        }
    }
    const handleMouseEnterMobile = () => {
        if (viewportWidth > 1000) {
            setIsHoveredMobile(true)
        }
    }
    const handleMouseLeaveMobile = () => {
        if (viewportWidth > 1000) {
            setIsHoveredMobile(false)
        }
    }

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Custom Website Design Sydney: Tailored Solutions for Your
                    Business
                </title>
                <meta
                    name="description"
                    content="Unlock success with our Custom Website Design services in Sydney. Tailored solutions for your business's unique online presence. Elevate your brand today."
                />
                <meta
                    name="keywords"
                    content="web design Sydney, fast web hosting sydney, Custom Website Design Sydney, Best Web Hosting parramatta, Sydney web design company"
                />
            </Helmet>
            {/* <TopBanner name="Services" /> */}
            <TopBanner2 name="Services" />
            <div className={styles.firstDiv}>
                <ScrollAnimation>
                    <h4>
                        What's a great recipe for accelerating your business's
                        growth? It includes a complete marketing plan, a
                        high-converting website, access to advanced automation
                        and reporting technologies, and, most crucially, the
                        appropriate people on your team. Here's where the
                        Murphy's team can help.
                    </h4>
                </ScrollAnimation>
                <ScrollAnimation>
                    <h1 className={styles.textnew}>
                        MURPHYS CAN HELP YOU REACH YOUR PERFORMANCE
                    </h1>
                </ScrollAnimation>
                <ScrollAnimation>
                    <h1 className={styles.textnew}>
                        GOALS WITH A FREE MARKETING PLAN.
                    </h1>
                </ScrollAnimation>
                <ScrollAnimation>
                    <FeatureList />
                </ScrollAnimation>
                <ScrollAnimation>
                    <h4>Ready to kickstart your 10X journey?</h4>
                </ScrollAnimation>
                <ScrollAnimation>
                    <a
                        className={styles1.btn}
                        href="https://calendly.com/digital-strategists-murphy/30-minute-strategy-session"
                        id={styles1.btn1}
                    >
                        Book 30 minute Free Consultation
                    </a>
                </ScrollAnimation>
                <div className={styles.services}>
                    <div className={styles.CardOuter}>
                        <div
                            className={
                                !isHoveredWeb ? styles.Card : styles.HoverClick
                            }
                            onMouseEnter={handleMouseEnterWeb}
                            onMouseLeave={handleMouseLeaveWeb}
                            onClick={handleWebClick}
                        >
                            <img
                                src={!isHoveredWeb ? photo1 : photo2}
                                alt="web design Sydney, fast web hosting sydney, Custom Website Design Sydney, Best Web Hosting parramatta, Sydney web design company"
                            />
                            <div className={styles.rectangle}></div>
                            <div className={styles.Icons}>
                                <div className={styles.circle1}></div>
                            </div>
                            <div className={styles.numberDiv}>
                                <p>01</p>
                            </div>
                            <div className={styles.text}>
                                {!isHoveredWeb && (
                                    <>
                                        <h2
                                            className={
                                                !isHoveredDesign
                                                    ? styles.black
                                                    : styles.white
                                            }
                                        >
                                            WEB DESIGN AND DEVELOPMENT
                                        </h2>
                                        <p>
                                            Want to create a fully customized
                                            website for your business? Provide
                                            us your business details and we will
                                            get it ready for you.
                                        </p>
                                        <div
                                            className={styles.arrowCircle}
                                            onClick={handleMouseEnterWeb}
                                        >
                                            <BsArrowRightCircle />
                                        </div>
                                    </>
                                )}
                                {isHoveredWeb && (
                                    <>
                                        <h3>
                                            Our Web Design and Development
                                            services includes following
                                            services:
                                        </h3>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Fully
                                            customized web design
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Creative
                                            design
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Custom CMS{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Wordpress
                                            development{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> eCommerce
                                            solution{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Static
                                            website{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Domain
                                            Registration{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Web hosting{' '}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.CardOuterR}>
                        <div
                            className={
                                !isHoveredDesign
                                    ? styles.CardR
                                    : styles.HoverClickR
                            }
                            onMouseEnter={handleMouseEnterDesign}
                            onMouseLeave={handleMouseLeaveDesign}
                            onClick={handleDesignClick}
                        >
                            <img
                                src={!isHoveredDesign ? photo1 : photo2}
                                alt="web design Sydney, fast web hosting sydney, Custom Website Design Sydney, Best Web Hosting parramatta, Sydney web design company"
                            />
                            <div className={styles.rectangle}></div>
                            <div className={styles.Icons}>
                                <div className={styles.circle2}></div>
                            </div>
                            <div className={styles.numberDiv}>
                                <p>02</p>
                            </div>
                            <div className={styles.text}>
                                {!isHoveredDesign && (
                                    <>
                                        <h2>GRAPHIC DESIGN AND BRANDING</h2>
                                        <p>
                                            What the point of growing the
                                            business if you don't have your own
                                            brand? We will help you to grow your
                                            business by building the brands you
                                            deserve.
                                        </p>
                                        <div className={styles.arrowCircle}>
                                            <BsArrowRightCircle />
                                        </div>
                                    </>
                                )}
                                {isHoveredDesign && (
                                    <>
                                        <h3>
                                            Our Graphic design and branding
                                            includes following services:
                                        </h3>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Logo design
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Business
                                            card
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Flyers{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Brochures
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Letterheads{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Invoice
                                            design{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Hoarding
                                            banners{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />{' '}
                                            Advertisement banners{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Vehicle
                                            Wraps{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Email
                                            marketing templates{' '}
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Banner and
                                            retargeting ads{' '}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.CardOuter}>
                        <div
                            className={
                                !isHoveredMarket
                                    ? styles.Card
                                    : styles.HoverClick
                            }
                            onMouseEnter={handleMouseEnterMarket}
                            onMouseLeave={handleMouseLeaveMarket}
                            onClick={handleMarketClick}
                        >
                            <img
                                src={!isHoveredMarket ? photo1 : photo2}
                                alt="web design Sydney, fast web hosting sydney, Custom Website Design Sydney, Best Web Hosting parramatta, Sydney web design company"
                            />
                            <div className={styles.rectangle}></div>
                            <div className={styles.Icons}>
                                <div className={styles.circle3}></div>
                            </div>
                            <div className={styles.numberDiv}>
                                <p>03</p>
                            </div>
                            <div className={styles.text}>
                                {!isHoveredMarket && (
                                    <>
                                        <h2>Digital Marketing and SEO</h2>
                                        <p>
                                            SEO, SEM, PPC, Facebook Boosting &
                                            Digital Marketing. Designed packages
                                            to increase your digital footprint
                                            and online visibility making it
                                            easier for potential leads to find
                                            you online..
                                        </p>
                                        <div className={styles.arrowCircle}>
                                            <BsArrowRightCircle />
                                        </div>
                                    </>
                                )}
                                {isHoveredMarket && (
                                    <>
                                        <h3>
                                            Our Digital Marketing and SEO
                                            includes following services:
                                        </h3>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle /> Search
                                            engine optimization
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Digital Marketing
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Pay Per Click - PPC
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Facebook Browsing
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Content Writing
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            CRM and Sales
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Inbound Marketing
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Promoting Products{' '}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={styles.CardOuterR}>
                        <div
                            className={
                                !isHoveredMobile
                                    ? `${styles.CardR} ${styles.CardRo}`
                                    : `${styles.HoverClickR} ${styles.HoverClickRo}`
                            }
                            onMouseEnter={handleMouseEnterMobile}
                            onMouseLeave={handleMouseLeaveMobile}
                            onClick={handleMobileClick}
                        >
                            <img
                                src={!isHoveredMobile ? photo1 : photo2}
                                alt="web design Sydney, fast web hosting sydney, Custom Website Design Sydney, Best Web Hosting parramatta, Sydney web design company"
                            />
                            <div className={styles.rectangle}></div>
                            <div className={styles.Icons}>
                                <div className={styles.circle4}></div>
                            </div>
                            <div className={styles.numberDiv}>
                                <p>04</p>
                            </div>
                            <div className={styles.text}>
                                {!isHoveredMobile && (
                                    <>
                                        <h2>MOBILE APPLICATION DEVELOPMENT</h2>
                                        <p>
                                            The planning and development of
                                            complicated systems is one of our
                                            main specialities. We are stepping
                                            forward in the mobile generation.
                                        </p>
                                        <div className={styles.arrowCircle}>
                                            <BsArrowRightCircle />
                                        </div>
                                    </>
                                )}
                                {isHoveredMobile && (
                                    <>
                                        <h3>
                                            Our Mobile application development
                                            includes following services:
                                        </h3>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            iOS App Development
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Andriod App Development
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Cross-Platform Development
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Corporate App Development
                                        </div>
                                        <div className={styles.para}>
                                            <AiOutlineCheckCircle />
                                            Ecommerce App Development
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GetAQuote />
            {/* <div class="wistia_responsive_padding" style={{ padding: " 56.25% 0 0 0", position: " relative " }}>
                <div class="wistia_responsive_wrapper" style={{ height: "100%", left: "0", position: "absolute", top: "0", width: "100%" }}>
                    <div class="wistia_embed wistia_async_lyd8ki010w seo=true videoFoam=true" style={{ height: "100%", position: "relative", width: "100%" }}>
                        <div class="wistia_swatch" style={{
                            height: "100%", left: "0", opacity: "0", overflow: "hidden", position: "absolute", top: "0", transition: "opacity 200ms", width: "100%"
                        }}>
                            <img src="https://fast.wistia.com/embed/medias/lyd8ki010w/swatch" style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%" }} alt="web design Sydney, fast web hosting sydney, Custom Website Design Sydney, Best Web Hosting parramatta, Sydney web design company" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                        </div>
                    </div>
                </div>
            </div > */}
            {/* <div class="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <div class="wistia_responsive_wrapper" style={{ height: "100%", left: "0", position: "absolute", top: "0", width: "100%" }}>
                    <iframe src="https://fast.wistia.net/embed/iframe/lyd8ki010w?seo=true&videoFoam=true" title="loopVideo" allow="autoplay" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%">
                    </iframe>
                </div>
            </div> */}
            {/* <GetAQuote /> */}
            {/* <div className={styles.Ideas}>
                Ideas
            </div> */}
        </>
    )
}

export default Services
