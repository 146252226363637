import React, { useState, useRef } from "react";
import styles from "./Accordion.module.css";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";

const faqs = [
  {
    id: 1,
    header: "How does the Affiliates Program work?",
    text: `You can share our services which are available on Murphys Technology with your audience through customised linking tools and earn money on qualifying purchases and customer actions like signing up. Give us a call on 02 7254 4827 to learn more or if you have any further questions.`,
  },
  {
    id: 2,
    header: "How do I qualify for this program?",
    text: `Individual, Bloggers, publishers and content creators with a qualifying website or mobile app can participate in this program. If you're an influencer with an established social media following you are eligible for this program. Give us a call on 02 7254 4827 to learn more or if you have any further questions.`,
  },
  {
    id: 3,
    header: "How do I sign up to the program?",
    text: `You can request to sign up by filling out the form above. We will review your application and approve it if you meet the qualifying criteria. Give us a call on 02 7254 4827 to learn more or if you have any further questions.`,
  },
  {
    id: 4,
    header: "How do I earn in this program?",
    text: `There are many variations of passages , but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <div className={styles.rcAccordionCard}>
      <div className={styles.rcAccordionHeader}>
        <div
          className={`${styles.rcAccordionToggle} p-3 ${
            active === id ? "active" : ""
          }`}
          onClick={() => handleToggle(id)}
        >
          <h5 className={styles.rcAccordionTitle}>{header}</h5>
          <div className={` ${styles.rcAccordionIcon}`}>
            {active !== id && <AiOutlinePlus />}
            {active === id && <AiOutlineMinus />}
          </div>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`${styles.rcCollapse} ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className={styles.rcAccordionBody}>
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const Accordion = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <>
      <div className="container-fluid mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="card">
              <div className="card-body">
                {faqs.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
