import React, { useState } from 'react'
import Stepper from './components/Stepper'
import { StepperContextProvider } from './contexts/StepperContext'
// import StepperControl from './components/StepperControl'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Step4 from './components/Step4'
import Step5 from './components/Step5'
import Step6 from './components/Step6'
import Step7 from './components/Step7'
import Step8 from './components/Step8'
import Step9 from './components/Step9'
import Step10 from './components/Step10'
import Step11 from './components/Step11'
import Step12 from './components/Step12'
import Step13 from './components/Step13'
import Step14 from './components/Step14'
import Step15 from './components/Step15'
import Step16 from './components/Step16'
import Step17 from './components/Step17'
import Step18 from './components/Step18'
import Step19 from './components/Step19'
import { useParams } from 'react-router-dom';
import { FaLessThan } from 'react-icons/fa6'
// eslint-disable-next-line
import Details from './components/Details'
// eslint-disable-next-line
import Complete from './components/Complete'
import { useLocation } from 'react-router';
import stylesNav from './SurveyNavbar.module.css'

import styles from './StrategySession.module.css'


const StrategySession = () => {
    // eslint-disable-next-line
    const { id } = useParams();
    const [currentStep, setCurrentStep] = useState(parseInt(id, 10) || 1 ? parseInt(id, 10) || 1 : 1)

    // eslint-disable-next-line
    const [userData, setUserData] = useState();
    // eslint-disable-next-line
    const [finalData, setFinalData] = useState([]);

    const steps = [
        "Step 1",
        "Step 2",
        "Step 3",
        "Step 4",
        "Step 5",
        "Step 6",
        "Step 7",
        "Step 8",
        "Step 9",
        "Step 10",
        "Step 11",
        "Step 12",
        "Step 13",
        "Step 14",
        "Step 15",
        "Step 16",
        "Step 17",
        "Step 18",
        "Step 19"
    ]

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <Step1 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 2:
                return <Step2 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 3:
                return <Step3 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 4:
                return <Step4 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 5:
                return <Step5 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 6:
                return <Step6 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 7:
                return <Step7 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 8:
                return <Step8 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 9:
                return <Step9 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 10:
                return <Step10 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 11:
                return <Step11 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 12:
                return <Step12 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 13:
                return <Step13 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 14:
                return <Step14 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 15:
                return <Step15 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 16:
                return <Step16 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 17:
                return <Step17 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 18:
                return <Step18 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            case 19:
                return <Step19 handleClick={handleClick} currentStep={currentStep} steps={steps} />
            default:
                break;
        }
    }
    const handleClick = (direction) => {
        let newStep = currentStep;
        direction === "next" ? newStep++ : newStep--;
        // check if steps are in bound
        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
    }
    const location = useLocation();
    let locationString = location.pathname.slice(1);
    const shouldShowNavbar = locationString.includes('StrategySession');

    return (
        <>
            {shouldShowNavbar && currentStep !== 18 &&
                <div className={stylesNav.TopHeader}>
                    <button onClick={() => handleClick("back")}
                        className={currentStep === 1 || currentStep === 19 ? `${stylesNav.finalButton} ${stylesNav.btn}` : `${stylesNav.Button} ${stylesNav.btn}`}>
                        <div>
                            <FaLessThan />
                        </div>
                    </button>
                    <h1>Murphy's Technology</h1>
                </div>
            }
            {/* currentStep !== 18 && */}
            <div className={styles.Body} style={currentStep === 18 ? { width: '100%', margin: "0", padding: '0' } : {}}>
                {/* Stepper */}
                {currentStep !== 18 && currentStep !== 19 &&
                    <Stepper steps={steps} currentStep={currentStep} />
                }
                {/* Display components */}
                <div className={styles.Content} style={currentStep === 18 ? { width: '100%', margin: "0", padding: '0' } : {}}>
                    {/* <form> */}
                    <StepperContextProvider>
                        {displayStep(currentStep)}
                    </StepperContextProvider>
                    {/* </form> */}
                    {/* navigation controls */}
                    {/* <StepperControl handleClick={handleClick} currentStep={currentStep} steps={steps} /> */}
                </div>

            </div>
        </>

    )
}

export default StrategySession
