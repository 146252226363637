import { useContext, useState, useEffect } from 'react'
import { StepperContext } from '../contexts/StepperContext'
import React from 'react'
import styles from './Content.module.css'
import Marquee from "react-fast-marquee";
import { BsArrowRight } from 'react-icons/bs';

const Step7 = ({ handleClick, currentStep, steps }) => {
    const { userData, setUserData } = useContext(StepperContext);
    const [selectedOption, setSelectedOption] = useState(userData.option);
    const [isbuttonDisabled, setisbuttonDisabled] = useState(true);
    useEffect(() => {
        if (userData.option === undefined || userData.option === "") {
            setisbuttonDisabled(true); // Enable the button if either checkbox is checked
        } else {
            setisbuttonDisabled(false); // Disable the button if both checkboxes are unchecked
        }
    }, [userData.option]);
    if (!userData) {
        return <div>Loading...</div> // or some other fallback behavior
    }
    // eslint-disable-next-line
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value })
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value })
    };
    // console.log(userData)
    return (
        <>
            <div className={`${styles.StepOuterDiv} ${styles.Step3OuterDiv}`}>
                <h1>How much is your monthly marketing budget?</h1>
                {/* <input onChange={handleChange} value={userData["userEmail"] || ""} name="userEmail" placeholder='Email*' /> */}
                <div className={styles.optionsDiv}>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="Under $3k"
                                checked={selectedOption === "Under $3k"}
                                onChange={handleOptionChange}
                            /> Under $3k
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="$3k - $5k"
                                checked={selectedOption === "$3k - $5k"}
                                onChange={handleOptionChange}
                            /> $3k - $5k
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="$5k - $10k"
                                checked={selectedOption === "$5k - $10k"}
                                onChange={handleOptionChange}
                            /> $5k - $10k
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="$10k - $20k"
                                checked={selectedOption === "$10k - $20k"}
                                onChange={handleOptionChange}
                            /> $10k - $20k
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="$20k - $35k"
                                checked={selectedOption === "$20k - $35k"}
                                onChange={handleOptionChange}
                            /> $20k - $35k
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="$35k - $50k"
                                checked={selectedOption === "$35k - $50k"}
                                onChange={handleOptionChange}
                            />  $35k - $50k
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="option"
                                value="$50k+"
                                checked={selectedOption === "$50k+"}
                                onChange={handleOptionChange}
                            />  $50k+
                        </label>
                    </div>
                </div>
            </div>
            <div className={styles.ButtonDiv}>
                {/* next */}
                <button className={styles.btn} id={styles.btn1} onClick={() => handleClick("next")} disabled={isbuttonDisabled}>
                    <div>
                        Next <Marquee direction="right" speed="30" ><BsArrowRight /></Marquee>
                    </div>
                </button>
            </div>
        </>
    )
}

export default Step7
